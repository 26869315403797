import { Container, AppBar, Toolbar, Typography, Divider, IconButton, Avatar, Button, Skeleton } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { stringAvatar, createNewTheme } from '../functions/utils';
import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function AvaliacoesLoja() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [tipoLoja, setTipoLoja] = useState("");
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [avaliacoes, setAvaliacoes] = useState(null)

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/perfil-loja", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };
  
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      handleBack();
    };

    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleCardapio = () => {
    navigate('/', { replace: true, state: { page: "cardapio" } });
  };

  useEffect(() => {
    handleOpenLoading();
    
    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;
    const tipoLoja = Cookies.get('tpLj');
    setTipoLoja(tipoLoja)
    
    if(colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    getInfos();
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/avaliacoes.php`, {
      idestabelecimentos: 1
    })
      .then(response => {
        const informacoes = response.data
        if (informacoes) {
          setAvaliacoes(informacoes);
          handleCloseLoading();
          return;
        }
        setAvaliacoes([]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleGetTipoLoja = () => {
    switch (tipoLoja) {
      case "restaurante":
        return <>cardápio</>
      case "loja":
        return <>catálogo</>
    }
  };

  const handleComentario = (avaliacao) => {
    const partes = avaliacao.split(" -- ");
    return partes[1]
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Avaliações da loja
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        {avaliacoes ? (
          <>
            {avaliacoes.length === 0 && (
              <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                  <img src="https://dedicado.mely.online/imagens/no-order.png" />

                  <div style={{ height: "10px" }}></div>

                  Essa loja ainda não possui avaliações <br />Que tal ser o primeiro, faça já seu pedido e avalie!

                  <div style={{ height: "20px" }}></div>

                  <Button variant="text" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleCardapio}>Ir para o {handleGetTipoLoja()}</Button>
                </Typography>
              </Typography>
            )}
            {avaliacoes.length !== 0 && (
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {avaliacoes.map((avaliacao) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar {...stringAvatar(avaliacao.nomecliente)} style={{ fontSize: "12px", margin: "auto", width: "24px", height: "24px" }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={avaliacao.nomecliente}
                        primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
                        secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: "14px", color: "#999" }}>
                              {handleComentario(avaliacao.avaliacao)}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            )}
          </>
        ) : (
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ fontSize: "12px", margin: "auto", width: "24px", height: "24px" }} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Skeleton animation="wave" height={30} width="100%" />
                  </>
                }
                secondary={
                  <>
                    <Skeleton animation="wave" height={30} width="100%" />
                  </>
                }
              />
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar style={{ fontSize: "12px", margin: "auto", width: "24px", height: "24px" }} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Skeleton animation="wave" height={30} width="100%" />
                  </>
                }
                secondary={
                  <>
                    <Skeleton animation="wave" height={30} width="100%" />
                  </>
                }
              />
            </ListItem>
            <Divider />
          </List>
        )}

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default AvaliacoesLoja;