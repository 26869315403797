import { Container, AppBar, Toolbar, IconButton, Typography, Avatar, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ArrowBackOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import Cookies from 'js-cookie';

import '@fontsource/roboto/400.css';

function PerfilLoja() {

  const state = useLocation().state;
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [urlBase, setUrlBase] = useState("");
  const [nome, setNome] = useState('');
  const [isMesa, setIsMesa] = useState(false);
  const [onlyMenu, setOnlyMenu] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: page } })
    } else {
      navigate("/", { replace: true, state: { page: page } })
    }
  };
  
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      handleBack();
    };

    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleFidelidade = () => {
    navigate('/fidelidade', { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });
  };

  const handleInformacoes = () => {
    navigate('/informacoes', { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });
  };

  const handleAvaliacoes = () => {
    navigate('/avaliacoes-loja', { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });
  };

  useEffect(() => {

    const onlyMenu = Cookies.get('eNbM') === "true";
    if (onlyMenu) {
      setOnlyMenu(onlyMenu)
    }

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    setNome(mainName)
    const url = Cookies.get('UBs');
    setUrlBase(url)

    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
    }
  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Perfil da loja
          </Toolbar>
        </AppBar>

        <div style={{ height: "83px" }}></div>

        <>
          <Avatar src={`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`} style={{ margin: "auto", width: "48px", height: "48px" }} />

          <div style={{ height: "12px" }}></div>

          <Typography style={{ fontSize: "16px", color: "#1B1B1B", textAlign: "center", fontWeight: "bold" }}>
            {nome}
          </Typography>

          <div style={{ height: "30px" }}></div>

          <List>
            <ListItem disablePadding divider={true}>
              <ListItemButton>
                <ListItemText primary="Informações" onClick={handleInformacoes} />
              </ListItemButton>
              <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
            </ListItem>
            {!onlyMenu && (
              <ListItem disablePadding divider={true}>
                <ListItemButton>
                  <ListItemText primary="Avaliações da loja" onClick={handleAvaliacoes} />
                </ListItemButton>
                <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
              </ListItem>
            )}
            {!isMesa && !onlyMenu && (
              <ListItem disablePadding divider={true}>
                <ListItemButton onClick={handleFidelidade}>
                  <ListItemText primary="Programa Fidelidade" />
                </ListItemButton>
                <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
              </ListItem>
            )}
          </List>
        </>

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default PerfilLoja;