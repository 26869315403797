import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Dialog, Slide, Radio, Divider, Button, Skeleton, Fab, InputAdornment, IconButton, Grid } from '@mui/material';
import { AddOutlined, ArrowBackOutlined, Check, CheckBox, CloseOutlined, DeliveryDiningOutlined, EditCalendarOutlined, EventAvailableOutlined, Facebook, Favorite, FavoriteBorderOutlined, Instagram, LinkOutlined, LocalOfferOutlined, MonetizationOnOutlined, QueryBuilderOutlined, RemoveOutlined, RoomServiceOutlined, SearchOutlined, ShareOutlined, Star, TableBarOutlined, Telegram, TimerOutlined, TrendingUpOutlined, WhatsApp } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { createNewTheme, dateToTimestamp, formatarData, toReal } from '../../functions/utils';
import { Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dashboard({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [pedidosEntrega, setPedidosEntrega] = useState("0");
  const [pedidosMesa, setPedidosMesa] = useState("0");
  const [pedidosRetirada, setPedidosRetirada] = useState("0");
  const [totalPedidos, setTotalPedidos] = useState("0");
  const [faturamentoEntrega, setFaturamentoEntrega] = useState("0");
  const [faturamentoMesa, setFaturamentoMesa] = useState("0");
  const [faturamentoRetirada, setFaturamentoRetirada] = useState("0");
  const [totalFaturamento, setTotalFaturamento] = useState("0");
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [graficoEntrega, setGraficoEntrega] = useState();
  const [graficoMesa, setGraficoMesa] = useState();
  const [graficoRetirada, setGraficoRetirada] = useState();
  const [diasSemana, setDiasSemana] = useState();

  const navigate = useNavigate();

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const formataData = (data) => {
    const value = data.replace(/\D/g, '');
    const formattedValue = value
      .replace(/^(\d{2})(\d{0,2})/, '$1/$2')
      .replace(/^(\d{2})\/(\d{2})(\d{0,4})/, '$1/$2/$3');
    if (formattedValue.charAt(formattedValue.length - 1) === "/") {
      return formattedValue.slice(0, -1) + "";
    }
    return formattedValue
  }

  const handlePeriodoInicial = (event) => {
    const dataFormatada = formataData(event.target.value)
    setDataInicial(dataFormatada);
  };

  const handlePeriodoFinal = (event) => {
    const dataFormatada = formataData(event.target.value)
    setDataFinal(dataFormatada);
  };

  useEffect(() => {
    const dataAtual = new Date();
    dataAtual.setHours(23, 59, 59, 999);
    const dataSeteDiasAtras = new Date();
    dataSeteDiasAtras.setDate(dataSeteDiasAtras.getDate());
    dataSeteDiasAtras.setHours(0, 0, 0, 0);

    const dateAtual = new Date(dataAtual.getTime());
    const dateSeteDiasAtras = new Date(dataSeteDiasAtras.getTime());
    setDataInicial(formatarData(dateSeteDiasAtras))
    setDataFinal(formatarData(dateAtual))
    const timestampDataAtual = parseInt(dataAtual.getTime() / 1000);
    const timestampDataSeteDiasAtras = parseInt(dataSeteDiasAtras.getTime() / 1000);
    getDashboard(timestampDataSeteDiasAtras, timestampDataAtual);
  }, [update]);

  const getDashboard = (dataInicial, dataFinal) => {
    const idestabelecimento = Cookies.get("Md");
    const urlbase = Cookies.get("UBs");
    axios.get(`${urlbase}/dash-pdv-v2.php?idestabelecimentos=${idestabelecimento}&datainicial=${dataInicial}&datafinal=${dataFinal + 86399}`)
      .then(response => {
        response = response.data
        console.log(response)
        if (!response) {
          handleSnack("Você não possui pedidos durante o período selecionado.")
          return
        }
        setFaturamentoEntrega(response.faturamentoEntrega)
        setFaturamentoMesa(response.faturamentoMesa)
        setFaturamentoRetirada(response.faturamentoRetirada)
        setTotalFaturamento(parseFloat(response.faturamentoEntrega) + parseFloat(response.faturamentoMesa) + parseFloat(response.faturamentoRetirada))
        setPedidosEntrega(response.pedidosEntrega)
        setPedidosMesa(response.pedidosMesa)
        setPedidosRetirada(response.pedidosRetirada)
        setTotalPedidos(parseInt(response.pedidosEntrega) + parseInt(response.pedidosMesa) + parseInt(response.pedidosRetirada))

        setGraficoEntrega([parseInt(response.entrega7), parseInt(response.entrega6), parseInt(response.entrega5), parseInt(response.entrega4), parseInt(response.entrega3), parseInt(response.entrega2), parseInt(response.entrega1)])
        setGraficoMesa([parseInt(response.mesa7), parseInt(response.mesa6), parseInt(response.mesa5), parseInt(response.mesa4), parseInt(response.mesa3), parseInt(response.mesa2), parseInt(response.mesa1)])
        setGraficoRetirada([parseInt(response.retirada7), parseInt(response.retirada6), parseInt(response.retirada5), parseInt(response.retirada4), parseInt(response.retirada3), parseInt(response.retirada2), parseInt(response.retirada1)])

        setDiasSemana([returnDia(response.diasemana7), returnDia(response.diasemana6), returnDia(response.diasemana5), returnDia(response.diasemana4), returnDia(response.diasemana3), returnDia(response.diasemana2), returnDia(response.diasemana1)])

        handleCloseLoading();
      })
      .catch(error => {
        handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
      });
  };

  const returnDia = (dia) => {
    switch (dia) {
      case "1":
        return "Segunda";
      case "2":
        return "Terça";
      case "3":
        return "Quarta";
      case "4":
        return "Quinta";
      case "5":
        return "Sexta";
      case "6":
        return "Sabado";
      case "7":
        return "Domingo";
    }
  }
  const handleAtualizarDashboard = () => {
    handleOpenLoading();
    getDashboard(dateToTimestamp(dataInicial), dateToTimestamp(dataFinal))
  };

  const data = {
    labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho'],
    datasets: [
      {
        label: 'Vendas',
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };
  const valueFormatter = (value) => `${value}mm`;

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
              style={{ zIndex: "10001" }}
            />

            <Typography style={{ fontWeight: "bold", paddingTop: 11 }}>Análise de desempenho</Typography>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "right", marginTop: '-20px' }}>
              <TextField id="outlined-basic" label="Data inicial do período"
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditCalendarOutlined />
                    </InputAdornment>
                  ),
                }} variant="outlined" value={dataInicial} onChange={handlePeriodoInicial} />
              <Typography style={{ fontSize: 14, fontWeight: "bold", padding: "0px 10px 0px 10px" }}>até</Typography>
              <TextField id="outlined-basic" label="Data final do período"
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditCalendarOutlined />
                    </InputAdornment>
                  ),
                }} variant="outlined" value={dataFinal} onChange={handlePeriodoFinal} />
              <Button disableElevation endIcon={<SearchOutlined />} variant="contained" size="large" style={{ height: "56px", fontWeight: "bold", textTransform: "capitalize", marginLeft: "10px", color: "#fff" }} onClick={handleAtualizarDashboard}>Buscar</Button>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "6px", marginBottom: "-33px", background: "#0284c7", height: 40, width: 40, borderRadius: "50%" }}><DeliveryDiningOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Pedidos entrega</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}>{pedidosEntrega}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#84cc16", height: 40, width: 40, borderRadius: "50%" }}><TableBarOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Pedidos mesa</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}>{pedidosMesa}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "6px", marginBottom: "-33px", background: "#9333ea", height: 40, width: 40, borderRadius: "50%" }}><RoomServiceOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Pedidos retirada</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}>{pedidosRetirada}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#64748b", height: 40, width: 40, borderRadius: "50%" }}><TrendingUpOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Total de pedidos</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}>{totalPedidos}</Typography>
              </Paper>
            </div>
            <div style={{ height: "20px" }}></div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#0284c7", height: 40, width: 40, borderRadius: "50%" }}><MonetizationOnOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Faturamento entrega</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}><strong style={{ fontSize: "12px" }}>R$</strong>{toReal(faturamentoEntrega)}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#84cc16", height: 40, width: 40, borderRadius: "50%" }}><MonetizationOnOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Faturamento mesa</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}><strong style={{ fontSize: "12px" }}>R$</strong>{toReal(faturamentoMesa)}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px', marginRight: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#9333ea", height: 40, width: 40, borderRadius: "50%" }}><MonetizationOnOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Faturamento retirada</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}><strong style={{ fontSize: "12px" }}>R$</strong>{toReal(faturamentoRetirada)}</Typography>
              </Paper>
              <Paper style={{ flex: '1', padding: '20px' }}>
                <Typography style={{ color: "white", marginLeft: "auto", textAlign: "center", paddingTop: "8px", marginBottom: "-33px", background: "#64748b", height: 40, width: 40, borderRadius: "50%" }}><MonetizationOnOutlined style={{ padding: 0 }} /></Typography>
                <Typography style={{ color: "#64748b", textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Total do faturamento</Typography>
                <Typography style={{ color: "#475569", textAlign: "center", fontWeight: "bold", fontSize: "32px" }}><strong style={{ fontSize: "12px" }}>R$</strong>{toReal(totalFaturamento)}</Typography>
              </Paper>
            </div>
            {graficoEntrega && graficoMesa && graficoRetirada && (
              <>
                <Paper style={{ marginTop: '20px', padding: '20px', width: '100%' }}>
                  <Typography style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Gráfico de Vendas</Typography>
                  <BarChart
                    xAxis={[{ scaleType: 'band', data: diasSemana }]}
                    series={[
                      { data: graficoEntrega, stack: 'A', label: 'Pedidos entrega' },
                      { data: graficoMesa, stack: 'B', label: 'Pedidos mesa' },
                      { data: graficoRetirada, stack: 'C', label: 'Pedidos retirada' },
                    ]}
                    height={400}
                    colors={['#0284c7', '#84cc16', '#9333ea']}
                  />
                </Paper>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Dashboard;