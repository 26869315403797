import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme, getDataMes, maskReais, formatarData } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, ListItemText, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Add, AddOutlined, AttachFileOutlined, Close, CloseOutlined, FilterList, NorthEastOutlined, RemoveOutlined, SearchOutlined, SouthEastOutlined } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MaterialUISwitch } from '../../components/utils';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const formasPagamentoVale = ["alelo", "sodexo", "ticket", "vrbeneficios", "flash", "greencard", "vb", "ifoodalimentacao"];
const formasPagamento = ["mastercard", "banricompras", "sorocred", "americanexpress", "aura", "credicard", "hipercard", "elo", "verdecard", "pan", "dinnersclub", "visa", "crédito"];

const formas = ['Todas as formas de pagamento', 'Crédito', 'Débito', 'Pix', 'Dinheiro', 'Outro'];

const tipos = ['Todos os tipos', 'Compra', 'Venda'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Fechamento({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const divConteudoRef = useRef();
  const divConteudoResumoRef = useRef();
  const divConteudoCompletoRef = useRef();

  const [responseFechamento, setResponseFechamento] = useState();
  const [despesasOriginal, setDespesasOriginal] = useState();
  const [despesas, setDespesas] = useState();
  const [despesaSelecionada, setDespesaSelecionada] = useState(-1);
  const [pesquisar, setPesquisar] = useState("");
  const [timestampInicio, setTimestampInicio] = useState("");
  const [timestampFim, setTimestampFim] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [isFiltrando, setIsFiltrando] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalFiltros, setOpenModalFiltros] = useState(false);
  const [filtroPeriodoDe, setFiltroPeriodoDe] = useState(null);
  const [filtroPeriodoAte, setFiltroPeriodoAte] = useState(null);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleOpenFiltros = (event) => {
    setOpenModalFiltros(true);
  };

  const handleCloseModalRelatorio = () => {
    setOpenModalFiltros(false)
  };

  const handlePesquisar = (event) => {
    setDespesas(despesasOriginal.filter(item => item.NM_FNC.toLowerCase().includes(event.target.value.toLowerCase())))
    setPesquisar(event.target.value);
  };

  const handleFiltrar = () => {
    handleOpenLoading();
    if (!filtroPeriodoDe || !filtroPeriodoAte) {
      handleCloseLoading()
      update("", `${Date.now()}___warning___Você precisa selecionar as datas de inicio e fim para filtrar.`)
      return
    }
    handleCloseLoading()

    axios.get(`${urlBase}/fechamento-filtro.php?&de=${filtroPeriodoDe.unix()}&ate=${filtroPeriodoAte.unix() + 86399}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let arrFechamento = []
        response = response.data
        if (response) {
          setResponseFechamento(response)
          for (const fechamento of response) {
            if (fechamento.RFT_CT === "S") {
              if (!arrFechamento[fechamento.DT_DESPESA]) {
                arrFechamento[fechamento.DT_DESPESA] = {}
                arrFechamento[fechamento.DT_DESPESA]["data"] = fechamento.DT_DESPESA
                arrFechamento[fechamento.DT_DESPESA]["entradas"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saidas"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valorfinal"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valoresmely"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valoresmanual"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saldoinicial"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["pix"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["dinheiro"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["credito"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["debito"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["vale"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["naoinformado"] = "0,00"
              }
              if (fechamento.TP_CMP === "saldoinicial") {
                arrFechamento[fechamento.DT_DESPESA]["saldoinicial"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saldoinicial"].replaceAll(".", "").replaceAll(",", '.')))
              }
              if (fechamento.TP_CMP === "venda") {
                if (fechamento.CT_FNC === "Mely") {
                  arrFechamento[fechamento.DT_DESPESA]["valoresmely"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["valoresmely"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.CT_FNC !== "Mely") {
                  arrFechamento[fechamento.DT_DESPESA]["valoresmanual"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["valoresmanual"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("dinheiro")) {
                  arrFechamento[fechamento.DT_DESPESA]["dinheiro"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["dinheiro"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (!fechamento.FM_PG || (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("link"))) {
                  arrFechamento[fechamento.DT_DESPESA]["naoinformado"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["naoinformado"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase() == "pix") {
                  arrFechamento[fechamento.DT_DESPESA]["pix"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["pix"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (formasPagamento.includes(fechamento.FM_PG.toLowerCase())) {
                  arrFechamento[fechamento.DT_DESPESA]["credito"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["credito"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG.toLowerCase().includes("débito")) {
                  arrFechamento[fechamento.DT_DESPESA]["debito"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["debito"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (formasPagamentoVale.includes(fechamento.FM_PG.toLowerCase())) {
                  arrFechamento[fechamento.DT_DESPESA]["vale"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["vale"].replaceAll(".", "").replaceAll(",", '.')))
                }
              }

              arrFechamento[fechamento.DT_DESPESA]["data"] = fechamento.DT_DESPESA
              if (fechamento.TP_CMP === "venda") {
                arrFechamento[fechamento.DT_DESPESA]["entradas"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["entradas"].replaceAll(".", "").replaceAll(",", '.')))
              }
              if (fechamento.TP_CMP === "compra") {
                arrFechamento[fechamento.DT_DESPESA]["saidas"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidas"].replaceAll(".", "").replaceAll(",", '.')))
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("dinheiro")) {
                  arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"].replaceAll(".", "").replaceAll(",", '.')))
                }
              }
              arrFechamento[fechamento.DT_DESPESA]["valorfinal"] = toReal(parseFloat(arrFechamento[fechamento.DT_DESPESA]["entradas"].replaceAll(".", "").replaceAll(",", '.')) - parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidas"].replaceAll(".", "").replaceAll(",", '.')))
            }
          }

          if (!despesasOriginal) {
            setDespesasOriginal(arrFechamento)
          }
          setDespesas(arrFechamento)
        } else {
          setResponseFechamento(null)
          setDespesas(null)
          setDespesasOriginal(null)
        }
        setOpenModalFiltros(false)
        setIsFiltrando(true)
        handleCloseLoading();
      })
      .catch(error => {
        console.log(error)
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });

  }

  const getDespesas = (url, inicio, fim) => {
    handleOpenLoading()
    setIsFiltrando(false)
    axios.get(`${url}/fechamento.php?inicio=${inicio}&fim=${fim}`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let arrFechamento = []
        response = response.data
        if (response) {
          setResponseFechamento(response)
          for (const fechamento of response) {
            if (fechamento.RFT_CT === "S") {
              if (!arrFechamento[fechamento.DT_DESPESA]) {
                arrFechamento[fechamento.DT_DESPESA] = {}
                arrFechamento[fechamento.DT_DESPESA]["data"] = fechamento.DT_DESPESA
                arrFechamento[fechamento.DT_DESPESA]["entradas"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saidas"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valorfinal"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valoresmely"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["valoresmanual"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["saldoinicial"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["pix"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["dinheiro"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["credito"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["debito"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["vale"] = "0,00"
                arrFechamento[fechamento.DT_DESPESA]["naoinformado"] = "0,00"
              }
              if (fechamento.TP_CMP === "saldoinicial") {
                arrFechamento[fechamento.DT_DESPESA]["saldoinicial"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saldoinicial"].replaceAll(".", "").replaceAll(",", '.')))
              }
              if (fechamento.TP_CMP === "venda") {
                if (fechamento.CT_FNC === "Mely") {
                  arrFechamento[fechamento.DT_DESPESA]["valoresmely"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["valoresmely"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.CT_FNC !== "Mely") {
                  arrFechamento[fechamento.DT_DESPESA]["valoresmanual"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["valoresmanual"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("dinheiro")) {
                  arrFechamento[fechamento.DT_DESPESA]["dinheiro"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["dinheiro"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (!fechamento.FM_PG || (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("link"))) {
                  arrFechamento[fechamento.DT_DESPESA]["naoinformado"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["naoinformado"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase() == "pix") {
                  arrFechamento[fechamento.DT_DESPESA]["pix"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["pix"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (formasPagamento.includes(fechamento.FM_PG.toLowerCase())) {
                  arrFechamento[fechamento.DT_DESPESA]["credito"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["credito"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (fechamento.FM_PG.toLowerCase().includes("débito")) {
                  arrFechamento[fechamento.DT_DESPESA]["debito"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["debito"].replaceAll(".", "").replaceAll(",", '.')))
                }
                if (formasPagamentoVale.includes(fechamento.FM_PG.toLowerCase())) {
                  arrFechamento[fechamento.DT_DESPESA]["vale"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["vale"].replaceAll(".", "").replaceAll(",", '.')))
                }
              }

              arrFechamento[fechamento.DT_DESPESA]["data"] = fechamento.DT_DESPESA
              if (fechamento.TP_CMP === "venda") {
                arrFechamento[fechamento.DT_DESPESA]["entradas"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["entradas"].replaceAll(".", "").replaceAll(",", '.')))
              }
              if (fechamento.TP_CMP === "compra") {
                arrFechamento[fechamento.DT_DESPESA]["saidas"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidas"].replaceAll(".", "").replaceAll(",", '.')))
                if (fechamento.FM_PG && fechamento.FM_PG.toLowerCase().includes("dinheiro")) {
                  arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"] = toReal(parseFloat(fechamento.QT_DSP.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidasdinheiro"].replaceAll(".", "").replaceAll(",", '.')))
                }
              }
              arrFechamento[fechamento.DT_DESPESA]["valorfinal"] = toReal(parseFloat(arrFechamento[fechamento.DT_DESPESA]["entradas"].replaceAll(".", "").replaceAll(",", '.')) - parseFloat(arrFechamento[fechamento.DT_DESPESA]["saidas"].replaceAll(".", "").replaceAll(",", '.')))
            }
          }

          if (!despesasOriginal) {
            setDespesasOriginal(arrFechamento)
          }
          setDespesas(arrFechamento)
        } else {
          setResponseFechamento(null)
          setDespesas(null)
          setDespesasOriginal(null)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  useEffect(() => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');
    setUrlBase(urlBase)

    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999);
    const timestampFim = Math.floor(hoje.getTime() / 1000);
    const timestampInicio = timestampFim - 86399;
    setTimestampInicio(timestampInicio)
    setTimestampFim(timestampFim)
    getDespesas(urlBase, timestampInicio, timestampFim)
  }, []);

  const validaZerodespesas = (despesas) => {
    if (!despesas) {
      return true
    }
    return false
  }

  const handleFechamento = (cliente) => {
    setDespesaSelecionada(cliente)
  }

  const getDataFiltro = (filtro) => {
    switch (filtro) {
      case "de":
        return filtroPeriodoDe.format('DD/MM/YYYY')
      case "ate":
        return filtroPeriodoAte.format('DD/MM/YYYY')
    }
  };

  const handleCancelarFiltros = () => {
    getDespesas(urlBase, timestampInicio, timestampFim);
    setIsFiltrando(false)
  };

  const imprimirDiv = () => {
    handleOpenLoading();
    const conteudo = divConteudoRef.current.innerHTML;
    const janelaImpressao = window.open('', '', 'height=600,width=800');

    janelaImpressao.document.write('<html><head><title>Imprimir</title>');
    const cssLinks = Array.from(document.querySelectorAll("link[rel='stylesheet']"));
    cssLinks.forEach(link => {
      janelaImpressao.document.write(`<link rel="stylesheet" type="text/css" href="${link.href}">`);
      janelaImpressao.document.write(`<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>`);
    });

    janelaImpressao.document.write(`</head><body style="font-family:'Roboto';" >`);
    janelaImpressao.document.write(conteudo);
    janelaImpressao.document.write('</body></html>');
    janelaImpressao.document.close();
    janelaImpressao.focus();
    janelaImpressao.print();
    // janelaImpressao.close();
    handleCloseLoading();
  };

  const imprimirDivResumo = () => {
    handleOpenLoading();
    setDespesaSelecionada(-1)
    setTimeout(() => {
      const conteudo = divConteudoResumoRef.current.innerHTML;
      const janelaImpressao = window.open('', '', 'height=600,width=800');

      janelaImpressao.document.write('<html><head><title>Imprimir</title>');
      const cssLinks = Array.from(document.querySelectorAll("link[rel='stylesheet']"));
      cssLinks.forEach(link => {
        janelaImpressao.document.write(`<link rel="stylesheet" type="text/css" href="${link.href}">`);
        janelaImpressao.document.write(`<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>`);
      });

      janelaImpressao.document.write(`</head><body style="font-family:'Roboto';" >`);
      janelaImpressao.document.write(conteudo);
      janelaImpressao.document.write('</body></html>');
      janelaImpressao.document.close();
      janelaImpressao.focus();
      janelaImpressao.print();
      // janelaImpressao.close();
      handleCloseLoading();
    }, 500);
  };

  const imprimirDivCompleto = () => {
    if (!responseFechamento) {
      update("", `${Date.now()}___warning___Não há valores suficientes para gerar um fechamento completo.`)
      return
    }
    handleOpenLoading();
    const conteudo = divConteudoCompletoRef.current.innerHTML;
    const janelaImpressao = window.open('', '', 'height=600,width=800');

    janelaImpressao.document.write('<html><head><title>Imprimir</title>');
    const cssLinks = Array.from(document.querySelectorAll("link[rel='stylesheet']"));
    cssLinks.forEach(link => {
      janelaImpressao.document.write(`<link rel="stylesheet" type="text/css" href="${link.href}">`);
      janelaImpressao.document.write(`<link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>`);
    });

    janelaImpressao.document.write(`</head><body style="font-family:'Roboto';" >`);
    janelaImpressao.document.write(conteudo);
    janelaImpressao.document.write('</body></html>');
    janelaImpressao.document.close();
    janelaImpressao.focus();
    janelaImpressao.print();
    // janelaImpressao.close();
    handleCloseLoading();
  };

  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };

  const getNomeCliente = (nome) => {
    if (nome && nome.includes("--")) {
      const arr = nome.split("--");
      return <>{arr[2].charAt(0).toUpperCase() + arr[2].slice(1)}</>
    }
    return nome
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const isValidDate = (data) => {
    const parsedDate = new Date(data);
    return !isNaN(parsedDate.getTime());
  };

  const getDataDespesa = (data) => {
    if (!isValidDate(data)) {
      return "-"
    }
    const dia = padZero(data.getDate());
    const mes = padZero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const horas = padZero(data.getHours());
    const minutos = padZero(data.getHours());
    return `${dia}/${mes}/${ano} - ${horas}:${minutos}`;
  }

  const getResumoFechamento = (despesas) => {

    let periodoDe, periodoAte, sistemaMely = "0,00", fluxoManual = "0,00", saldoInicial = "0,00"
    let pix = "0,00", dinheiro = "0,00", credito = "0,00", debito = "0,00", vale = "0,00", naoInformado = "0,00"
    let saidas = "0,00", saidasDinheiro = "0,00", entradas = "0,00", valorFinal = "0,00"

    if (timestampInicio && timestampFim) {
      periodoDe = formatarData(new Date(timestampInicio * 1000))
      periodoAte = formatarData(new Date(timestampFim * 1000))
    }
    if (isFiltrando) {
      periodoDe = formatarData(new Date(filtroPeriodoDe.unix() * 1000))
      periodoAte = formatarData(new Date(filtroPeriodoAte.unix() * 1000))
    }

    if (despesas) {
      Object.keys(despesas).map((key) => {
        console.log(despesas[key])
        sistemaMely = toReal(parseFloat(sistemaMely.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].valoresmely.replaceAll(".", "").replaceAll(",", '.')))
        fluxoManual = toReal(parseFloat(fluxoManual.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].valoresmanual.replaceAll(".", "").replaceAll(",", '.')))
        saldoInicial = toReal(parseFloat(saldoInicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].saldoinicial.replaceAll(".", "").replaceAll(",", '.')))

        pix = toReal(parseFloat(pix.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].pix.replaceAll(".", "").replaceAll(",", '.')))
        dinheiro = toReal(parseFloat(dinheiro.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].dinheiro.replaceAll(".", "").replaceAll(",", '.')))
        credito = toReal(parseFloat(credito.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].credito.replaceAll(".", "").replaceAll(",", '.')))
        debito = toReal(parseFloat(debito.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].debito.replaceAll(".", "").replaceAll(",", '.')))
        vale = toReal(parseFloat(vale.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].vale.replaceAll(".", "").replaceAll(",", '.')))
        naoInformado = toReal(parseFloat(naoInformado.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].naoinformado.replaceAll(".", "").replaceAll(",", '.')))
        saidas = toReal(parseFloat(saidas.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].saidas.replaceAll(".", "").replaceAll(",", '.')))
        saidasDinheiro = toReal(parseFloat(saidasDinheiro.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].saidasdinheiro.replaceAll(".", "").replaceAll(",", '.')))
        entradas = toReal(parseFloat(entradas.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].entradas.replaceAll(".", "").replaceAll(",", '.')))
        valorFinal = toReal(parseFloat(valorFinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')))

      })
    }

    return <>
      <div ref={divConteudoCompletoRef} style={{ display: "none" }}>
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Detalhes do fechamento</Typography>

        <table style={{ width: "100%", marginTop: 26 }}>
          <tr>
            <th style={{ textAlign: "left" }}>Nome</th>
            <th style={{ textAlign: "left", width: 200 }}>Categoria</th>
            <th style={{ textAlign: "center", width: 150 }}>Data</th>
            <th style={{ textAlign: "center", width: 120 }}>Valor</th>
          </tr>
        </table>

        {responseFechamento &&
          responseFechamento.map((cliente) => (
            <table style={{ width: "100%", textAlign: "left", background: "#F2F2F2", borderRadius: 8, marginBottom: 0, cursor: "pointer" }}>
              <tr>
                <td style={{ textAlign: "left" }}>{getNomeCliente(cliente.NM_FNC)}</td>
                <td style={{ textAlign: "left", width: 200 }}>{cliente.CT_FNC}</td>
                <td style={{ textAlign: "center", width: 150 }}>{getDataDespesa(new Date(cliente.DT_DSP * 1000))}</td>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography style={{ color: cliente.TP_CMP === "compra" ? "#F94A3D" : "#6B9F29", fontWeight: "bold", borderRadius: 4 }}>
                    {cliente.TP_CMP === "compra" ? "- " : ""}R$ {toReal(parseFloat(cliente.QT_DSP.replaceAll(".", "").replaceAll(",", '.')))}
                  </Typography>
                </td>
              </tr>
            </table>
          ))
        }
      </div>

      <div ref={divConteudoResumoRef} style={{ display: "none" }}>
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Resumo do fechamento</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Typography style={{ fontWeight: "600", fontSize: 14 }}>Periodo</Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            De
            <b style={{ marginLeft: "auto" }}>{periodoDe}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Ate
            <b style={{ marginLeft: "auto" }}>{periodoAte}</b>
          </Typography>
        </div>

        <Divider />

        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Entradas por categoria</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Saldo inicial do caixa
            <b style={{ marginLeft: "auto" }}>R$ {saldoInicial}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Sistema MELY
            <b style={{ marginLeft: "auto" }}>R$ {sistemaMely}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Fluxo manual
            <b style={{ marginLeft: "auto" }}>R$ {fluxoManual}</b>
          </Typography>
        </div>

        <Divider />

        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Entradas</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Dinheiro
            <b style={{ marginLeft: "auto" }}>R$ {dinheiro}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            PIX
            <b style={{ marginLeft: "auto" }}>R$ {pix}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Crédito
            <b style={{ marginLeft: "auto" }}>R$ {credito}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Débito
            <b style={{ marginLeft: "auto" }}>R$ {debito}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Vale
            <b style={{ marginLeft: "auto" }}>R$ {vale}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Outros
            <b style={{ marginLeft: "auto" }}>R$ {naoInformado}</b>
          </Typography>
        </div>

        <Divider />

        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Saídas</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Despesas em dinheiro
            <b style={{ marginLeft: "auto" }}>R$ {saidasDinheiro}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Despesas gerais
            <b style={{ marginLeft: "auto" }}>R$ {saidas}</b>
          </Typography>
        </div>

        <Divider />

        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Resumo total dos valores</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Saldo final do caixa
            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(saldoInicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(dinheiro.replaceAll(".", "").replaceAll(",", '.'))) - parseFloat(saidasDinheiro.replaceAll(".", "").replaceAll(",", '.')))}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Total de entradas
            <b style={{ marginLeft: "auto" }}>R$ {entradas}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Total de saidas
            <b style={{ marginLeft: "auto" }}>R$ {saidas}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Lucro
            <b style={{ marginLeft: "auto" }}>R$ {valorFinal}</b>
          </Typography>
          <Divider />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 16 }}>
            Resumo total
            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(valorFinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(saldoInicial.replaceAll(".", "").replaceAll(",", '.'))))}</b>
          </Typography>
        </div>
      </div>

      <div>
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo do fechamento</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", fontSize: 14 }}>Periodo</Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            De
            <b style={{ marginLeft: "auto" }}>{periodoDe}</b>
          </Typography>
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Ate
            <b style={{ marginLeft: "auto" }}>{periodoAte}</b>
          </Typography>
        </div>
        <div style={{ height: 10 }} />
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas por categoria</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Saldo inicial do caixa
            <b style={{ marginLeft: "auto" }}>R$ {saldoInicial}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Sistema MELY
            <b style={{ marginLeft: "auto" }}>R$ {sistemaMely}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Fluxo manual
            <b style={{ marginLeft: "auto" }}>R$ {fluxoManual}</b>
          </Typography>

        </div>
        <div style={{ height: 10 }} />
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Dinheiro
            <b style={{ marginLeft: "auto" }}>R$ {dinheiro}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            PIX
            <b style={{ marginLeft: "auto" }}>R$ {pix}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Crédito
            <b style={{ marginLeft: "auto" }}>R$ {credito}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Débito
            <b style={{ marginLeft: "auto" }}>R$ {debito}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Vale
            <b style={{ marginLeft: "auto" }}>R$ {vale}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Outros
            <b style={{ marginLeft: "auto" }}>R$ {naoInformado}</b>
          </Typography>
        </div>

        <div style={{ height: 10 }} />

        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Saídas</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Despesas em dinheiro
            <b style={{ marginLeft: "auto" }}>R$ {saidasDinheiro}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Despesas gerais
            <b style={{ marginLeft: "auto" }}>R$ {saidas}</b>
          </Typography>

        </div>

        <div style={{ height: 10 }} />
        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo total dos valores</Typography>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
            Saldo final do caixa
            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(saldoInicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(dinheiro.replaceAll(".", "").replaceAll(",", '.'))) - parseFloat(saidasDinheiro.replaceAll(".", "").replaceAll(",", '.')))}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Total de entradas
            <b style={{ marginLeft: "auto" }}>R$ {entradas}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Total de saidas
            <b style={{ marginLeft: "auto" }}>R$ {saidas}</b>
          </Typography>

          <div style={{ height: 10 }} />
          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
            Lucro
            <b style={{ marginLeft: "auto" }}>R$ {valorFinal}</b>
          </Typography>

          <div style={{ height: 16 }} />
          <Divider />
          <div style={{ height: 16 }} />

          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 16 }}>
            Resumo total
            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(valorFinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(saldoInicial.replaceAll(".", "").replaceAll(",", '.'))))}</b>
          </Typography>

          <div style={{ height: 24 }} />

        </div>
      </div>
    </>
  }
  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <Dialog
        open={openModalFiltros}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 640 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <FilterList />&nbsp;&nbsp;Filtrar
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalRelatorio}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>De</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoDe}
                  onChange={(newValue) => setFiltroPeriodoDe(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Até</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoAte}
                  onChange={(newValue) => setFiltroPeriodoAte(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseModalRelatorio} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          <Button variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={handleFiltrar}>Filtrar</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute", overflow: "hidden" }}>
        <Grid container spacing={2} style={{ padding: "16px 0px 16px 16px" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={7} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", height: "100vh" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Fechamento de Caixa</Typography>
                  <div style={{ marginLeft: "auto", paddingRight: 16 }}>
                    <Tooltip title={`Filtrar`}>
                      <IconButton onClick={handleOpenFiltros} size="medium" color="inherit" style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48 }}>
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <div>
                  <div style={{ height: 40 }} />
                  <Grid container spacing={2} style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto", paddingBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
                    {despesas &&
                      <>
                        {validaZerodespesas(despesasOriginal) ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 328px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                                <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                                <div style={{ height: "10px" }}></div>

                                Não há lançamentos para listar

                                <div style={{ height: "20px" }}></div>
                              </div>
                            </Grid>
                          </>
                          :
                          <>
                            <TextField
                              fullWidth
                              hiddenLabel
                              value={pesquisar}
                              onChange={handlePesquisar}
                              variant="outlined"
                              size="large"
                              placeholder="Pesquisar por Nome"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <InputAdornment style={{ color: "#444" }}>
                                      <SearchOutlined />
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                            />
                            <Divider style={{ width: "100%" }} />
                            <div style={{ height: 20 }} />
                          </>
                        }

                        {isFiltrando &&
                          <Grid item xs={12} sm={12} md={12} style={{ padding: 0 }}>
                            <div style={{ height: 20 }} />
                            <Typography style={{ fontWeight: "500", fontSize: 14, color: "#464545", background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                                <Grid item>
                                  Limpar filtros
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>De {getDataFiltro("de")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                                <Grid item>
                                  <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>Até {getDataFiltro("ate")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                                </Grid>
                              </Grid>
                            </Typography>
                            <div style={{ height: 10 }} />
                          </Grid>
                        }

                        <table style={{ width: "100%", marginTop: 16 }}>
                          <tr>
                            <th style={{ width: 60 }}></th>
                            <th style={{ textAlign: "left" }}>Data</th>
                            <th style={{ textAlign: "left", width: 120 }}>Entrada</th>
                            <th style={{ textAlign: "center", width: 120 }}>Saída</th>
                            <th style={{ textAlign: "center", width: 120 }}>Lucro</th>
                          </tr>
                        </table>

                        {Object.keys(despesas).map((key) => (
                          <Tooltip title={"Clique para ver detalhes"}>
                            <table style={{ width: "100%", textAlign: "left", background: "#F2F2F2", borderRadius: 8, marginBottom: 6, cursor: "pointer" }} onClick={() => handleFechamento(despesas[key])}>
                              <tr>
                                <td style={{ padding: 6, width: 60, textAlign: "center" }}>
                                  {parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')) < 0 ?
                                    <RemoveOutlined style={{ color: "#F94A3D" }} />
                                    :
                                    <AddOutlined style={{ color: "#6B9F29" }} />
                                  }
                                </td>
                                <td style={{ textAlign: "left" }}>{despesas[key].data}</td>
                                <td style={{ textAlign: "left", width: 120 }}>R$ {despesas[key].entradas}</td>
                                <td style={{ textAlign: "center", width: 120 }}>R$ {despesas[key].saidas}</td>
                                <td style={{ textAlign: "center", width: 120 }}>
                                  <Typography style={{ background: parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')) < 0 ? "#F94A3D" : "#6B9F29", fontWeight: "bold", paddingTop: 2, paddingBottom: 2, borderRadius: 4, margin: 10, color: "white" }}>
                                    R$ {toReal(parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')) < 0 ? -parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')) : parseFloat(despesas[key].valorfinal.replaceAll(".", "").replaceAll(",", '.')))}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </Tooltip>
                        ))}
                      </>
                    }

                  </Grid>
                </div>
                <div style={{ padding: 16, marginTop: "auto", display: "flex", maxHeight: 80 }}>
                  <Button variant="contained" size="medium" fullWidth style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={imprimirDivResumo}>
                    Imprimir resumo
                  </Button>
                  <Button variant="contained" size="medium" fullWidth style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={imprimirDivCompleto}>
                    Imprimir fechamento completo
                  </Button>
                </div>
              </Grid>

              {despesaSelecionada === -1 ?
                <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                  {getResumoFechamento(despesas)}
                </Grid>
                :
                <>
                  <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                    <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>Resumo - {despesaSelecionada.data}
                      <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setDespesaSelecionada(-1)}>
                        <Close />
                      </IconButton>
                    </Typography>
                    <div style={{ height: 10 }} />
                    <Divider style={{ width: "120%", marginLeft: -16 }} />
                    <div style={{ height: 24 }} />

                    <div ref={divConteudoRef} style={{ display: "none" }}>
                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Resumo do dia</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Data
                          <b style={{ marginLeft: "auto" }}>{despesaSelecionada.data}</b>
                        </Typography>
                      </div>

                      <Divider />

                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Entradas por categoria</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Saldo inicial do caixa
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saldoinicial}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Sistema MELY
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmely}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Fluxo manual
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmanual}</b>
                        </Typography>

                      </div>

                      <Divider />

                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Entradas no caixa</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Dinheiro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.dinheiro}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          PIX
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.pix}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Crédito
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.credito}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Débito
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.debito}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Vale
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.vale}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Outros
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.naoinformado}</b>
                        </Typography>
                      </div>

                      <Divider />

                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Saídas no caixa</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Despesas em dinheiro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidasdinheiro}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Despesas gerais
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                        </Typography>
                      </div>

                      <Divider />

                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 0 }}>Resumo total dos valores</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Saldo final do caixa
                          <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.dinheiro.replaceAll(".", "").replaceAll(",", '.'))) - parseFloat(despesaSelecionada.saidasdinheiro.replaceAll(".", "").replaceAll(",", '.')))}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Total de entradas
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.entradas}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Total de saidas
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                        </Typography>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Lucro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valorfinal}</b>
                        </Typography>

                        <Divider />

                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 16 }}>
                          Resumo total
                          <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.valorfinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.'))))}</b>
                        </Typography>
                      </div>
                    </div>

                    <div>
                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo do dia</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Data
                          <b style={{ marginLeft: "auto" }}>{despesaSelecionada.data}</b>
                        </Typography>
                      </div>
                      <div style={{ height: 10 }} />
                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas por categoria</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Saldo inicial do caixa
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saldoinicial}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Sistema MELY
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmely}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Fluxo manual
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmanual}</b>
                        </Typography>

                      </div>
                      <div style={{ height: 10 }} />
                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas no caixa</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Dinheiro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.dinheiro}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          PIX
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.pix}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Crédito
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.credito}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Débito
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.debito}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Vale
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.vale}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Outros
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.naoinformado}</b>
                        </Typography>
                      </div>

                      <div style={{ height: 10 }} />

                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Saídas no caixa</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Despesas em dinheiro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidasdinheiro}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Despesas gerais
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                        </Typography>

                      </div>

                      <div style={{ height: 10 }} />
                      <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo total dos valores</Typography>
                      <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                          Saldo final do caixa
                          <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.dinheiro.replaceAll(".", "").replaceAll(",", '.'))) - parseFloat(despesaSelecionada.saidasdinheiro.replaceAll(".", "").replaceAll(",", '.')))}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Total de entradas
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.entradas}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Total de saidas
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                        </Typography>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Lucro
                          <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valorfinal}</b>
                        </Typography>

                        <div style={{ height: 16 }} />
                        <Divider />
                        <div style={{ height: 16 }} />

                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 16 }}>
                          Resumo total
                          <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.valorfinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.'))))}</b>
                        </Typography>
                      </div>
                    </div>
                    <Button variant="contained" size="medium" fullWidth style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={imprimirDiv}>Imprimir</Button>

                    <div style={{ height: 24 }}></div>

                  </Grid>

                  {isMobile &&
                    <SwipeableDrawer
                      container={container}
                      anchor="bottom"
                      open={despesaSelecionada !== -1}
                      onClose={() => setDespesaSelecionada(-1)}
                      disableSwipeToOpen={true}
                      disableDiscovery={true}
                      ModalProps={{
                        keepMounted: true,
                        style: { zIndex: "10000" }
                      }}
                    >
                      <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} style={{ padding: 16, overflowX: "hidden", overflowY: "auto" }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>Resumo - {despesaSelecionada.data}
                          <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setDespesaSelecionada(-1)}>
                            <Close />
                          </IconButton>
                        </Typography>
                        <div style={{ height: 10 }} />
                        <Divider style={{ width: "120%", marginLeft: -16 }} />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo do dia</Typography>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Data
                            <b style={{ marginLeft: "auto" }}>{despesaSelecionada.data}</b>
                          </Typography>
                        </div>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas por categoria</Typography>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                            Saldo inicial do caixa
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saldoinicial}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Sistema MELY
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmely}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Fluxo manual
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valoresmanual}</b>
                          </Typography>

                        </div>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Entradas no caixa</Typography>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                            Dinheiro
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.dinheiro}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            PIX
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.pix}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Crédito
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.credito}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Débito
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.debito}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Vale
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.vale}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Outros
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.naoinformado}</b>
                          </Typography>
                        </div>

                        <div style={{ height: 10 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Saídas no caixa</Typography>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                            Despesas em dinheiro
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidasdinheiro}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Despesas gerais
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                          </Typography>

                        </div>

                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16, background: "#F2F2F2", paddingLeft: 16, paddingRight: 16, paddingTop: 10, paddingBottom: 10 }}>Resumo total dos valores</Typography>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14, color: "green" }}>
                            Saldo final do caixa
                            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.dinheiro.replaceAll(".", "").replaceAll(",", '.'))) - parseFloat(despesaSelecionada.saidasdinheiro.replaceAll(".", "").replaceAll(",", '.')))}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Total de entradas
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.entradas}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Total de saidas
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.saidas}</b>
                          </Typography>

                          <div style={{ height: 10 }} />
                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 14 }}>
                            Lucro
                            <b style={{ marginLeft: "auto" }}>R$ {despesaSelecionada.valorfinal}</b>
                          </Typography>

                          <div style={{ height: 16 }} />
                          <Divider />
                          <div style={{ height: 16 }} />

                          <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center", fontSize: 16 }}>
                            Resumo total
                            <b style={{ marginLeft: "auto" }}>R$ {toReal((parseFloat(despesaSelecionada.valorfinal.replaceAll(".", "").replaceAll(",", '.')) + parseFloat(despesaSelecionada.saldoinicial.replaceAll(".", "").replaceAll(",", '.'))))}</b>
                          </Typography>
                        </div>

                        <div style={{ height: 10 }} />

                        <Button variant="contained" size="medium" fullWidth style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={imprimirDiv}>Imprimir</Button>

                        <div style={{ height: 24 }}></div>
                      </div>
                    </SwipeableDrawer>
                  }
                </>
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Fechamento;