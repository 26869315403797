import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme, stringAvatar } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import { Avatar, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { Close, SearchOutlined, Stars } from '@mui/icons-material';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

function Clientes({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {
  
  const { windows } = props;
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [clientesOriginal, setClientesOriginal] = useState();
  const [clientes, setClientes] = useState();
  const [clienteSelecionado, setClienteSelecionado] = useState(0);
  const [pesquisar, setPesquisar] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handlePesquisar = (event) => {
    setClientes(clientesOriginal.filter(item => item.nome.toLowerCase().includes(event.target.value.toLowerCase()) || item.telefone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").includes(event.target.value)))
    setPesquisar(event.target.value);
  };

  const getUsuarios = (url) => {
    handleOpenLoading()
    axios.get(`${url}/clientes.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        if (response) {
          if (!clientesOriginal) {
            setClientesOriginal(response)
          }
          setClientes(response)
        }
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading()
        update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  useEffect(() => {
    handleOpenLoading()
    const urlBase = Cookies.get('UBs');

    getUsuarios(urlBase)
  }, []);

  const validaZeroClientes = (clientes) => {
    if (clientes && clientes.length === 0) {
      return true
    }
    return false
  }

  const handleCliente = (cliente) => {
    setClienteSelecionado(cliente)
  }

  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute", overflow: "hidden" }}>
        <Grid container spacing={2} style={{ padding: "16px 0px 16px 16px" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={7} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", height: "100vh" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold", color: "#1B1B1B", paddingTop: 11 }}>Clientes</Typography>
                </div>

                <div>
                  <div style={{ height: 40 }} />
                  <Grid container spacing={2} style={{ maxHeight: "calc(100vh - 192px)", overflow: "auto", paddingBottom: 24, paddingRight: 16, paddingLeft: 16 }}>
                    {clientes &&
                      <>
                        {validaZeroClientes(clientesOriginal) ?
                          <>
                            <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 328px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                                <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                                <div style={{ height: "10px" }}></div>

                                Não há clientes para listar

                                <div style={{ height: "20px" }}></div>
                              </div>
                            </Grid>
                          </>
                          :
                          <>
                            <TextField
                              fullWidth
                              hiddenLabel
                              value={pesquisar}
                              onChange={handlePesquisar}
                              variant="outlined"
                              size="large"
                              placeholder="Buscar por (Nome ou Telefone)"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <InputAdornment style={{ color: "#444" }}>
                                      <SearchOutlined />
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                            />
                            <Divider style={{ width: "100%" }} />
                            <div style={{ height: 20 }} />
                          </>
                        }

                        <table style={{ width: "100%", marginTop: 16 }}>
                          <tr>
                            <th style={{ width: 60 }}></th>
                            <th style={{ textAlign: "left" }}>Nome</th>
                            <th style={{ textAlign: "center", width: 120 }}>Gastos</th>
                          </tr>
                        </table>
                        {clientes.map((cliente) => (
                          <Tooltip title={"Clique para ver detalhes do cliente"}>
                            <table style={{ width: "100%", textAlign: "left", background: "#F2F2F2", borderRadius: 8, marginBottom: 6, cursor: "pointer" }} onClick={() => handleCliente(cliente)}>
                              <tr>
                                <td style={{ padding: 6, width: 60, textAlign: "center" }}><Avatar {...stringAvatar(cliente.nome)} style={{ width: "38px", height: "38px", fontSize: 14 }} /></td>
                                <td style={{ textAlign: "left" }}>{cliente.nome}</td>
                                <td style={{ textAlign: "center", width: 120 }}>
                                  <Typography style={{ background: "#6B9F29", fontWeight: "bold", paddingTop: 2, paddingBottom: 2, borderRadius: 4, margin: 10, color: "white" }}>
                                    R$ {toReal(parseFloat(cliente.total_valor_pedidos))}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </Tooltip>
                        ))}
                      </>
                    }

                  </Grid>
                </div>
              </Grid>

              {clienteSelecionado === 0 ?
                <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                    <img src="https://dedicado.mely.online/imagens/no-order-est.png" style={{ width: 155 }} />

                    <div style={{ height: "10px" }}></div>

                    Selecione um cliente ao lado para ver mais detalhes

                    <div style={{ height: "20px" }}></div>
                  </div>
                </Grid>
                :
                <>
                  <Grid item xs={12} sm={12} md={5} style={{ height: "calc(100vh - 75px)", overflowY: "auto", overflowX: "hidden", paddingRight: 16 }}>
                    <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{clienteSelecionado.nome}
                      <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setClienteSelecionado(0)}>
                        <Close />
                      </IconButton>
                    </Typography>
                    <div style={{ height: 10 }} />
                    <Divider style={{ width: "120%", marginLeft: -16 }} />
                    <div style={{ height: 24 }} />

                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados do cliente</Typography>
                    <div style={{ height: 10 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      E-mail
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.email}</label>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Telefone
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.telefone}</label>
                    </Typography>
                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />
                    <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados valores</Typography>
                    <div style={{ height: 10 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Pedidos
                      <label style={{ marginLeft: "auto" }}>{clienteSelecionado.total_pedidos}</label>
                    </Typography>
                    <div style={{ height: 5 }} />
                    <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                      Valor total
                      <b style={{ marginLeft: "auto" }}>R$ {toReal(parseFloat(clienteSelecionado.total_valor_pedidos))}</b>
                    </Typography>

                    <div style={{ height: 24 }} />
                    <Divider />
                    <div style={{ height: 24 }} />

                    <Typography style={{ fontWeight: "600", fontSize: 16, textAlign: "center" }}><Stars style={{ color: "#1976d2", marginBottom: -6 }} /> &nbsp;&nbsp; Pontos acumulados &nbsp;&nbsp; <Stars style={{ color: "#1976d2", marginBottom: -6 }} /></Typography>
                    <div style={{ height: 20 }} />
                    <Typography style={{ background: "#1976d2", color: "#fff", textAlign: "center", padding: 10, borderRadius: 8, fontWeight: "bold" }}>{clienteSelecionado.pontos ? clienteSelecionado.pontos : 0}</Typography>
                    <div style={{ height: 24 }} />

                  </Grid>

                  {isMobile &&
                    <SwipeableDrawer
                      container={container}
                      anchor="bottom"
                      open={clienteSelecionado !== 0}
                      onClose={() => setClienteSelecionado(0)}
                      disableSwipeToOpen={true}
                      disableDiscovery={true}
                      ModalProps={{
                        keepMounted: true,
                        style: { zIndex: "10000" }
                      }}
                    >
                      <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} style={{ padding: 16, overflowX: "hidden", overflowY: "auto" }}>
                        <Typography style={{ fontWeight: "600", display: "flex", alignItems: "center" }}>{clienteSelecionado.nome}
                          <IconButton style={{ marginLeft: "auto" }} size="small" onClick={() => setClienteSelecionado(0)}>
                            <Close />
                          </IconButton>
                        </Typography>
                        <div style={{ height: 10 }} />
                        <Divider style={{ width: "120%", marginLeft: -16 }} />
                        <div style={{ height: 24 }} />

                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados do cliente</Typography>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          E-mail
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.email}</label>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Telefone
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.telefone}</label>
                        </Typography>
                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />
                        <Typography style={{ fontWeight: "600", fontSize: 16 }}>Dados valores</Typography>
                        <div style={{ height: 10 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Pedidos
                          <label style={{ marginLeft: "auto" }}>{clienteSelecionado.total_pedidos}</label>
                        </Typography>
                        <div style={{ height: 5 }} />
                        <Typography style={{ fontWeight: "400", display: "flex", alignItems: "center", fontSize: 14 }}>
                          Valor total
                          <b style={{ marginLeft: "auto" }}>R$ {toReal(parseFloat(clienteSelecionado.total_valor_pedidos))}</b>
                        </Typography>

                        <div style={{ height: 24 }} />
                        <Divider />
                        <div style={{ height: 24 }} />

                        <Typography style={{ fontWeight: "600", fontSize: 16, textAlign: "center" }}><Stars style={{ color: "#1976d2", marginBottom: -6 }} /> &nbsp;&nbsp; Pontos acumulados &nbsp;&nbsp; <Stars style={{ color: "#1976d2", marginBottom: -6 }} /></Typography>
                        <div style={{ height: 20 }} />
                        <Typography style={{ background: "#1976d2", color: "#fff", textAlign: "center", padding: 10, borderRadius: 8, fontWeight: "bold" }}>{clienteSelecionado.pontos ? clienteSelecionado.pontos : 0}</Typography>
                        <div style={{ height: 24 }} />
                      </div>
                    </SwipeableDrawer>
                  }
                </>
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Clientes;