import { Container, AppBar, Toolbar, Button, IconButton, Typography, Card, CardContent, Divider, SwipeableDrawer, TextField, Skeleton } from '@mui/material';
import { ArrowBackOutlined, CheckCircle, Circle, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import '@fontsource/roboto/400.css';
import { getDatahora, traduzirMes } from '../functions/utils';

function InformacoesPagamento(props) {

  const { window } = props;
  const state = useLocation().state;

  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isMesa, setIsMesa] = useState(false);
  const [formasPagamento, setFormasPagamento] = useState(null);
  const [isInit, setIsInit] = useState(null);
  const [open, setOpen] = useState(false);
  const [tipoLoja, setTipoLoja] = useState("");
  const [modalAlert, setModalAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [numeroCartao, setNumeroCartao] = useState("");
  const [nomeCartao, setNomeCartao] = useState("");
  const [vencimentoCartao, setVencimentoCartao] = useState("");
  const [codigoSegurancaCartao, setCodigoSegurancaCarta] = useState("");
  const [cartaoSelected, setCartaoSelected] = useState("");

  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleBack = () => {
    navigate("/", { replace: true, state: { page: "perfil" } })
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCartao = (cartao) => {
    setOpen(true)
    setNumeroCartao(descriptografar(cartao.NR_CT))
    setNomeCartao(cartao.NM_USR)
    setVencimentoCartao(cartao.VL_CT)
    setCodigoSegurancaCarta(cartao.CD_CT)
    setCartaoSelected(cartao)
  }

  const validarApenasNumerosEspacos = (str) => {
    const regex = /^[0-9\s]+$/;
    return regex.test(str);
  }

  const handleCadastrarForma = () => {
    if (!cartaoSelected) {
      const urlBase = Cookies.get('UBs');
      const idusuarios = Cookies.get('iUsr');
      axios.post(`${urlBase}/cadastro_formapagamento.php`, {
        idusuarios: idusuarios,
        nome: nomeCartao,
        numero: numeroCartao,
        validade: vencimentoCartao,
        codigo: codigoSegurancaCartao,
        tipo: "credito"
      })
        .then(response => {
          response = response.data
          if (!response) {
            handleSnack("Não foi possivel conectar cadastrar-se, verifique sua conexão e tente novamente.")
            return;
          }
          if (response.resposta === "nome invalido") {
            handleSnack("O nome digitado não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "numero invalido") {
            handleSnack("O número do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "mes invalido") {
            handleSnack("O mês do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "ano invalido") {
            handleSnack("O ano do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "cartao vencido") {
            handleSnack("O cartão está vencido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "codigo invalido") {
            handleSnack("O código de segurança do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "falha") {
            handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
            return;
          }

          setOpen(false)
          setNumeroCartao("")
          setNomeCartao("")
          setVencimentoCartao("")
          setCodigoSegurancaCarta("")
          setCartaoSelected("")
          getFormasPagamento()
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    } else {
      const urlBase = Cookies.get('UBs');
      let numeroCard = numeroCartao
      if (descriptografar(cartaoSelected.NR_CT) === numeroCartao) {
        numeroCard = descriptografarCompleto(cartaoSelected.NR_CT)
      }
      if(!validarApenasNumerosEspacos(numeroCard)) {
        handleSnack("Você precisa preencher o número do cartão novamente para alterar.")
        return;
      }

      axios.post(`${urlBase}/atualiza_formapagamento.php`, {
        idcartao: cartaoSelected.ID_FM_PGT,
        nome: nomeCartao,
        numero: numeroCartao,
        validade: vencimentoCartao,
        codigo: codigoSegurancaCartao,
        tipo: "credito"
      })
        .then(response => {
          response = response.data
          if (!response) {
            handleSnack("Não foi possivel conectar cadastrar-se, verifique sua conexão e tente novamente.")
            return;
          }
          if (response.resposta === "nome invalido") {
            handleSnack("O nome digitado não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "numero invalido") {
            handleSnack("O número do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "mes invalido") {
            handleSnack("O mês do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "ano invalido") {
            handleSnack("O ano do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "cartao vencido") {
            handleSnack("O cartão está vencido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "codigo invalido") {
            handleSnack("O código de segurança do cartão não é válido, verifique e tente novamente.")
            return;
          }

          if (response.resposta === "falha") {
            handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
            return;
          }

          setOpen(false)
          setNumeroCartao("")
          setNomeCartao("")
          setVencimentoCartao("")
          setCodigoSegurancaCarta("")
          setCartaoSelected("")
          getFormasPagamento()
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    }
  }

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const tipoLoja = Cookies.get('tpLj');
    setTipoLoja(tipoLoja);
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;

    getFormasPagamento();

    setIsInit(true);
    handleCloseLoading();
  }, []);

  const handleNumeroCartao = (event) => {
    let valor = event.target.value.replace(/\D/g, '');
    valor = valor.replace(/(.{4})/g, '$1 ').trim();
    setNumeroCartao(valor.slice(0, 19));
  };

  const handleNomeCartao = (event) => {
    setNomeCartao(event.target.value.toUpperCase())
  };

  const handleVencimentoCartao = (event) => {
    let valor = event.target.value.replace(/\D/g, '');
    if (valor.length >= 2) {
      valor = valor.slice(0, 2) + '/' + valor.slice(2, 6);
    }
    setVencimentoCartao(valor);
  };

  const handleCodigoSegurancaCartao = (event) => {
    setCodigoSegurancaCarta(event.target.value)
  };

  const getFormasPagamento = () => {
    const urlBase = Cookies.get('UBs');
    const idusuarios = Cookies.get('iUsr');
    axios.get(`${urlBase}/formas-pagamento-usr.php?idusuarios=${idusuarios}`)
      .then(response => {
        if (response.data) {
          setIsInit(true);
          setFormasPagamento(response.data);
          handleCloseLoading();
          return;
        }
        setIsInit(true);
        setFormasPagamento([]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const descriptografar = (cartaocriptografado) => {
    const arr = atob(cartaocriptografado).split("aE");

    const lastdigits = atob(arr[1]);

    const numericPart = atob(arr[0]).replace(/\D/g, '');

    const strconvert1 = numericPart.substring(0, 4);
    const strconvert2 = numericPart.slice(-4);
    const strconvert3 = numericPart.substring(4, 8);
    return `●●●● ●●●● ●●●● ${lastdigits}`;
  }

  const descriptografarCompleto = (cartaocriptografado) => {
    const arr = atob(cartaocriptografado).split("aE");

    const lastdigits = atob(arr[1]);

    const numericPart = atob(arr[0]).replace(/\D/g, '');

    const strconvert1 = numericPart.substring(0, 4);
    const strconvert2 = numericPart.slice(-4);
    const strconvert3 = numericPart.substring(4, 8);
    return `${strconvert1} ${strconvert2} ${strconvert3} ${lastdigits}`;
  }

  const handleNovo = () => {
    setOpen(true)
    setNumeroCartao("")
    setNomeCartao("")
    setVencimentoCartao("")
    setCodigoSegurancaCarta("")
    setCartaoSelected("")
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        {isInit ? (
          <>
            <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
              <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
                  <ArrowBackOutlined />
                </IconButton>
                Formas de pagamento
              </Toolbar>
            </AppBar>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
            />

            <Dialog
              open={modalAlert}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Fechar</Button>
              </DialogActions>
            </Dialog>

            <div style={{ height: "83px" }}></div>


            {formasPagamento && (
              <>
                {formasPagamento.length === 0 && (
                  <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                    <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                      <img src="https://dedicado.mely.online/imagens/no-order.png" />

                      <div style={{ height: "10px" }}></div>

                      Você ainda não possui forma de pagamento. <br />Que tal criar a primeira agora?

                      <div style={{ height: "20px" }}></div>

                    </Typography>
                  </Typography>
                )}
              </>
            )}

            {formasPagamento ? (
              <>
                {formasPagamento.map((cartao) => (
                  <>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardHeader
                        avatar={
                          <CheckCircle style={{ paddingLeft: "10px" }} />
                        }
                        action={
                          <IconButton aria-label="settings" onClick={() => handleCartao(cartao)}>
                            <KeyboardArrowRightOutlined />
                          </IconButton>
                        }
                        title={descriptografar(cartao.NR_CT)}
                        titleTypographyProps={{ fontWeight: "bold" }}
                      />
                      <Divider />
                      <CardContent>
                        <Typography style={{ fontSize: "12px", color: "#999" }}>
                          {cartao.NM_USR}
                          <b style={{ float: "right" }}>{cartao.VL_CT}</b>
                        </Typography>
                      </CardContent>
                    </Card>
                    <div style={{ height: "24px" }} />
                  </>
                ))}
              </>
            ) : (
              <>
                <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <KeyboardArrowRightOutlined />
                      </IconButton>
                    }
                    title={
                      <>
                        <Skeleton animation="wave" height={36} width="240px" />
                      </>
                    }
                    titleTypographyProps={{ fontWeight: "bold" }}
                    style={{ color: "#1976d2" }}
                  />
                  <Divider />
                  <CardContent>
                    <Typography style={{ fontSize: "12px", color: "#999" }}>
                      <Skeleton animation="wave" height={36} width="240px" />
                    </Typography>
                  </CardContent>
                  <div style={{ height: "16px" }} />
                </Card>
              </>
            )}


            <SwipeableDrawer
              container={container}
              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableSwipeToOpen={true}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

              <Typography style={{ fontSize: "16px", paddingLeft: "20px", color: "#333", fontWeight: "bold" }}>
                {cartaoSelected ? "Editar forma de pagamento" : "Cadastrar forma de pagamento"}
              </Typography>
              <div style={{ height: "20px" }}></div>
              <Typography style={{ paddingLeft: "20px", fontSize: "12px", fontWeight: "600" }}>
                Número do cartão <b style={{ color: "#A51D1D" }}>*</b>
              </Typography>
              <div style={{ height: 4 }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={numeroCartao}
                  onChange={handleNumeroCartao}
                  variant="outlined"
                  placeholder="Número do cartão"
                  type="text"
                />
              </Typography>

              <div style={{ height: "10px" }}></div>
              <Typography style={{ paddingLeft: "20px", fontSize: "12px", fontWeight: "600" }}>
                Nome <b style={{ color: "#A51D1D" }}>*</b>
              </Typography>
              <div style={{ height: 4 }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={nomeCartao}
                  onChange={handleNomeCartao}
                  variant="outlined"
                  placeholder="Nome"
                  type="text"
                />
              </Typography>

              <div style={{ height: "10px" }}></div>
              <Typography style={{ paddingLeft: "20px", fontSize: "12px", fontWeight: "600" }}>
                Vencimento <b style={{ color: "#A51D1D" }}>*</b>
              </Typography>
              <div style={{ height: 4 }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={vencimentoCartao}
                  onChange={handleVencimentoCartao}
                  variant="outlined"
                  placeholder="Vencimento"
                  type="text"
                />
              </Typography>

              <div style={{ height: "10px" }}></div>
              <Typography style={{ paddingLeft: "20px", fontSize: "12px", fontWeight: "600" }}>
                Código de segurança <b style={{ color: "#A51D1D" }}>*</b>
              </Typography>
              <div style={{ height: 4 }}></div>
              <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={codigoSegurancaCartao}
                  onChange={handleCodigoSegurancaCartao}
                  variant="outlined"
                  placeholder="Código de segurança"
                  type="text"
                />
              </Typography>

              <Button variant="contained" size="large" style={{ textTransform: "none", margin: "20px" }} onClick={() => handleCadastrarForma()}>{cartaoSelected ? "Salvar" : "Cadastrar"}</Button>

            </SwipeableDrawer>

            <div style={{ height: "100px" }}></div>
          </>
        ) : (
          <>

            <Skeleton animation="wave" style={{ position: "absolute", top: "0", left: "0", right: "0", borderRadius: "0", height: "80px", marginTop: "-18px" }} />

            <div style={{ height: "70px" }}></div>

            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <KeyboardArrowRightOutlined />
                  </IconButton>
                }
                title={
                  <>
                    <Skeleton animation="wave" height={36} width="240px" />
                  </>
                }
                titleTypographyProps={{ fontWeight: "bold" }}
                style={{ color: "#1976d2" }}
              />
              <Divider />
              <CardContent>
                <Typography style={{ fontSize: "12px", color: "#999" }}>
                  <Skeleton animation="wave" height={36} width="240px" />
                </Typography>
              </CardContent>
              <div style={{ height: "16px" }}></div>
            </Card>
          </>
        )}
        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Button fullWidth variant="contained" size="large" style={{ textTransform: "none", fontWeight: "700" }} onClick={handleNovo}>
            Novo cartão
          </Button>
        </div>
      </Container >
    </ThemeProvider>
  );
}

export default InformacoesPagamento;