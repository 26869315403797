import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, Typography, IconButton, Button, Box, Tabs, Tab, Card, CardHeader, FormControl, RadioGroup, FormControlLabel, Radio, Skeleton, TextField, Divider, Grid } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, CheckCircleOutline, DeliveryDiningOutlined, HomeOutlined, LocalPrintshopOutlined, PaymentsOutlined, PrintDisabledOutlined, PrintOutlined, RestaurantOutlined, ShoppingBagOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { CustomTabPanel, a11yProps, toReal, createNewTheme } from '../../functions/utils';

import Snackbar from '@mui/material/Snackbar';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ColorlibConnector, ColorlibStepIcon } from '../../components/utils';

const steps = ["Cardápio", "Sacola", "Entrega", "Pagamento", "Revisar"];

function Enderecos({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const page = state ? state.page : 'home';
  const [isLogged, setIsLogged] = useState(false);
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [enderecoRetirada, setEnderecoRetirada] = useState("");
  const [enderecoEntrega, setEnderecoEntrega] = useState("");
  const [nomeEnderecoEntrega, setNomeEnderecoEntrega] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState(0);
  const [disableEntrega, setDisableEntrega] = useState(0);
  const [disableRetirada, setDisableRetirada] = useState(0);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [valorEntrega, setValorEntrega] = useState("-");
  const [endereco, setEndereco] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");
  const [numeroMesa, setNumeroMesa] = useState();
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msgSnack, setMsgSnack] = React.useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleSelecionarEndereco = () => {
    if (telefoneCliente.length < 11) {
      handleSnack("Você precisa digitar um telefone válido, com apenas números. Por exemplo.: 11912341234");
      return
    }
    Cookies.set('tE', 0, { expires: 7 });
    Cookies.set('tUsr', formatPhoneNumber(telefoneCliente), { expires: 7 });
    onChange("", "enderecos");

    onChange("", "selecionar-endereco")
  };

  const handleSelecionarTelefone = () => {
    setEnderecoEntrega("")
    Cookies.remove("eEA");
    Cookies.remove("tUsr");
  };

  const handleBack = () => {
    onChange("", "carrinho")
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    console.log("AQUI")
    onChange("", "/", { replace: true, state: { page: "pedidos" } })
  };

  const handlePagamentos = () => {
    if (enderecoEntrega) {
      Cookies.set('eEA', enderecoEntrega, { expires: 7 })
    }
    Cookies.set('tE', tipoEntrega, { expires: 7 });

    if (tipoEntrega === 0) {
      Cookies.remove('nM');
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
    }
    if (tipoEntrega === 1) {
      Cookies.remove('nM');
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
      Cookies.set('tUsr', formatPhoneNumber(telefoneCliente), { expires: 7 });
    }
    if (tipoEntrega === 2) {
      Cookies.set('nM', numeroMesa, { expires: 7 });
      Cookies.set('nmUsr', nomeCliente, { expires: 7 });
      Cookies.set('fmP', "definir", { expires: 7 });
      onChange("", "finalizar")
      return
    }
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      Cookies.set('fmP', "pontos", { expires: 7 });
      onChange("", "finalizar")
    } else {
      onChange("", "pagamentos")
    }
  };

  const handleChange = (event, newValue) => {
    setTipoEntrega(newValue);
    const valorTotal = Cookies.get('vlT');
    setValorTotalCarrinho(valorTotal);
    if (newValue === 0) {
      validaEndereco(newValue)
    }
    if (newValue === 1) {
      const nmUser = Cookies.get('nmUsr') ? Cookies.get('nmUsr') : ""
      const tUsr = Cookies.get('tUsr') ? Cookies.get('tUsr').replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "") : ""
      setNomeCliente(nmUser)
      setTelefoneCliente(tUsr)
      validaRetirada(tUsr, nmUser)
    }
    if (newValue === 2) {
      const nM = Cookies.get('nM') ? Cookies.get('nM') : "";
      setNumeroMesa(nM)
      validaMesa(nM)
    }
  };

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    getInfos();
    const valorTotal = Cookies.get('vlT');
    setValorTotalCarrinho(valorTotal)
    const entrega = Cookies.get('tE');
    if (entrega) {
      setTipoEntrega(parseInt(entrega));
      setButtonEnabled(false)
    }
    validaEndereco(entrega);

    let nomeCliente
    switch (entrega) {
      case "0":
        const enderecoEntregaAtual = Cookies.get('eEA');
        enderecoEntregaAtual ? setButtonEnabled(false) : setButtonEnabled(true)
        const telefoneCliente = Cookies.get('tUsr');
        telefoneCliente ? setTelefoneCliente(telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")) : setTelefoneCliente("")
        break;
      case "1":
        nomeCliente = Cookies.get('nmUsr');
        nomeCliente ? setButtonEnabled(false) : setButtonEnabled(true)
        setNomeCliente(nomeCliente)
        break;
      case "2":
        let numeroMesa = Cookies.get('nM');
        setNumeroMesa(numeroMesa)
        validaMesa(numeroMesa)
        break;
    }

    const entregasAtivas = Cookies.get('tD');
    entregasAtivas.includes("Entrega") ? setDisableEntrega(false) : setDisableEntrega(true);
    entregasAtivas.includes("Retirada") ? setDisableRetirada(false) : setDisableRetirada(true);
    if (!entregasAtivas.includes("Entrega")) {
      setButtonEnabled(false)
      setTipoEntrega(1)
    }
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/estabelecimentos.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const informacoes = response.data[0]
        const enderecoArr = informacoes.endereco.split("___")
        setEnderecoRetirada(enderecoArr[0])
        Cookies.set('eR', enderecoArr[0], { expires: 7 });
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const validaEndereco = (tipo) => {
    handleOpenLoading()
    console.log("tipo", tipo)
    !tipo ? tipo = 0 : console.log(":..")
    const isLogged = Cookies.get('isLogged');
    setIsLogged(isLogged)
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))

    const enderecoEntregaAtual = Cookies.get('eEA');
    enderecoEntregaAtual ? setButtonEnabled(false) : setButtonEnabled(true)
    setEnderecoEntrega(enderecoEntregaAtual)
    const nomeEndereco = Cookies.get('nmEA');
    setNomeEnderecoEntrega(nomeEndereco)
    if (enderecoEntregaAtual) {
      const mainId = Cookies.get('Md');
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/retornar-taxa.php`, {
        idestabelecimentos: mainId,
        endereco: enderecoEntregaAtual
      })
        .then(response => {
          response = response.data
          if (!response) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
            return
          }

          let bloquearCarrinho = Cookies.get('bCrt');
          if (bloquearCarrinho) {
            validaNome(nomeCliente)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta == "combinar" && parseInt(tipo) === 0) {
            validaNome(nomeCliente)
            Cookies.set('txE', "combinar", { expires: 7 });
            setValorTotalCarrinho(Cookies.get('vlT'))
            setValorEntrega("A Combinar")
          } else if (response.resposta == "0,00" && parseInt(tipo) === 0) {
            setValorTotalCarrinho(Cookies.get('vlT'))
            validaNome(nomeCliente)
            Cookies.set('txE', "0,00", { expires: 7 });
            setValorEntrega("Grátis")
          } else if (response.resposta.includes("endereço invalido") && parseInt(tipo) === 0) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("falha consultar") && parseInt(tipo) === 0) {
            setButtonEnabled(true)
            handleSnack('Algo deu errado com esse endereço, por favor verifique sua conexão e tente novamente.')
          } else if (response.resposta.includes("distancia ultrapassa") && parseInt(tipo) === 0) {
            setButtonEnabled(true)
            Cookies.remove('txE')
            handleSnack('Desculpe, o estabelecimento não trabalha com entregas no endereço indicado.')
          } else if (parseFloat(response.resposta.replaceAll(",", ".")) > 0 && parseInt(tipo) === 0) {
            setValorTotalCarrinho(toReal(parseFloat(Cookies.get('vlT').replaceAll(".", "").replaceAll(",", '.')) + parseFloat(response.resposta.replaceAll(".", "").replaceAll(",", '.'))))
            validaNome(nomeCliente)
            setValorEntrega(`R$ ${response.resposta}`)
            Cookies.set('txE', response.resposta, { expires: 7 });
          } else if (parseInt(tipo) === 1) {
            const telefoneCliente = Cookies.get('tUsr');
            telefoneCliente ? setTelefoneCliente(telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")) : setTelefoneCliente("")
            validaRetirada(telefoneCliente, nomeCliente)
            handleCloseLoading();
            return
          } else if (parseInt(tipo) === 2) {
            let numeroMesa = Cookies.get('nM');
            validaMesa(numeroMesa)
            handleCloseLoading();
            return
          } else {
            setButtonEnabled(true)
            handleSnack('Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.')
          }
          validaNome(nomeCliente);
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    } else {
      handleCloseLoading();
    }
  }

  const validaNome = (nome) => {
    console.log(nome)
    !nome ? setButtonEnabled(true) : setButtonEnabled(false)
  }

  const validaMesa = (mesa) => {
    parseInt(mesa) > 0 ? setButtonEnabled(false) : setButtonEnabled(true)
  };

  const validaRetirada = (telefoneCliente, nomeCliente) => {
    nomeCliente ? setButtonEnabled(false) : setButtonEnabled(true)
  };

  const handleNumeroMesa = (event) => {
    validaMesa(event.target.value)
    setNumeroMesa(event.target.value);
  };

  const handleNomeCliente = (event) => {
    if (tipoEntrega === 0) {
      if (valorEntrega !== "-" && valorEntrega) {
        validaNome(event.target.value);
      }
    } else {
      validaRetirada(telefoneCliente, event.target.value)
    }
    setNomeCliente(event.target.value);
  };

  const handleTelefoneCliente = (event) => {
    if (tipoEntrega === 1) {
      validaRetirada(event.target.value, nomeCliente)
    }
    setTelefoneCliente(event.target.value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7, 11)}`;
  };

  const handleChangeStepper = (label) => {
    switch (label) {
      case "Sacola":
        onChange("", "carrinho")
        break;
      case "Cardápio":
        onChange("", "cardapio-venda")
        break;
    }
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>

            <div style={{ height: "16px" }}></div>

            <Stepper alternativeLabel activeStep={2} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label} onClick={() => handleChangeStepper(label)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <div style={{ height: "16px" }}></div>
            <Divider />
            <div style={{ height: "16px" }}></div>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
            />

            <div style={{ height: "10px" }}></div>

            <Box sx={{ width: '100%' }}>
              <Tabs value={tipoEntrega} onChange={handleChange} variant="fullWidth" >
                <Tab label="Entrega" disabled={disableEntrega} {...a11yProps(0)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
                <Tab label="Retirada" disabled={disableRetirada} {...a11yProps(1)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
                <Tab label="Mesa" {...a11yProps(2)} style={{ textTransform: "capitalize", borderBottom: "1px solid #CCC" }} />
              </Tabs>
              <CustomTabPanel value={tipoEntrega} index={0}>
                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
                  {enderecoEntrega ? (
                    <>Entregar em:</>
                  ) : (
                    <>Informe o telefone do cliente para buscar os endereços cadastrados</>
                  )}
                  <div style={{ height: "10px" }}></div>
                  {enderecoEntrega ? (
                    <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                      <CardHeader
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        avatar={
                          <FormControl value={endereco}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel checked value="2" control={<Radio />} />
                            </RadioGroup>
                          </FormControl>
                        }
                        action={
                          <>
                            <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                              Alterar Endereço
                            </Button><br />
                            <Button variant="text" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarTelefone}>
                              Alterar Telefone
                            </Button>
                          </>
                        }
                        title={nomeEnderecoEntrega}
                        titleTypographyProps={{ fontSize: "12px", fontWeight: "500", marginLeft: "-20px", color: "#999" }}
                        subheader={enderecoEntrega}
                        subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", marginLeft: "-20px", color: "#1B1B1B" }}
                      />
                    </Card>
                  ) : (
                    <>
                      <div style={{ height: "10px" }}></div>
                      <TextField
                        fullWidth
                        hiddenLabel
                        value={telefoneCliente}
                        onChange={handleTelefoneCliente}
                        variant="outlined"
                        size="large"
                        label="Telefone do cliente"
                        type="text"
                      />
                      <div style={{ height: 22 }}></div>
                      <Button variant="contained" fullWidth aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold" }} onClick={handleSelecionarEndereco}>
                        Buscar
                      </Button>
                    </>
                  )}

                  {enderecoEntrega ? (
                    <>
                      <div style={{ height: "12px" }}></div>
                      Hoje, {tempoEntrega}
                      <div style={{ height: "10px" }}></div>

                      <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                        <CardHeader
                          title="Taxa de entrega"
                          titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                          subheader={valorEntrega}
                          subheaderTypographyProps={{ fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}
                        />
                      </Card>
                    </>
                  ) : (
                    <></>
                  )}

                  {enderecoEntrega ? (
                    <>
                      <div style={{ height: "12px" }}></div>
                      Dados do cliente:
                      <div style={{ height: "10px" }}></div>
                      <TextField
                        fullWidth
                        hiddenLabel
                        value={nomeCliente}
                        onChange={handleNomeCliente}
                        variant="outlined"
                        size="large"
                        label="Nome do cliente"
                        type="text"
                      />
                    </>
                  ) : (
                    <></>
                  )}

                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={tipoEntrega} index={1}>

                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>

                  Informe os dados abaixo:
                  <div style={{ height: "20px" }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={nomeCliente}
                    onChange={handleNomeCliente}
                    variant="outlined"
                    size="large"
                    label="Nome do cliente"
                    type="text"
                  />
                  <div style={{ height: "20px" }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={telefoneCliente}
                    onChange={handleTelefoneCliente}
                    variant="outlined"
                    size="large"
                    label="Telefone do cliente"
                    type="text"
                  />
                  <div style={{ height: "30px" }}></div>
                  <Divider />
                  <div style={{ height: "20px" }}></div>

                  Retirar em:
                  <div style={{ height: "10px" }}></div>
                  <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title="Local"
                      titleTypographyProps={{ fontSize: "12px", fontWeight: "500", color: "#999" }}
                      subheader={enderecoRetirada ? (
                        enderecoRetirada
                      ) : (
                        <Skeleton animation="wave" height={30} width="100%" />
                      )}
                      subheaderTypographyProps={{ fontSize: "14px", fontWeight: "bold", color: "#1B1B1B" }}
                    />
                  </Card>

                </Typography>
              </CustomTabPanel>

              <CustomTabPanel value={tipoEntrega} index={2}>
                <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold", marginLeft: "-22px", marginRight: "-22px" }}>
                  Informe os dados abaixo:
                  <div style={{ height: "20px" }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={numeroMesa}
                    onChange={handleNumeroMesa}
                    variant="outlined"
                    size="large"
                    label="Número da mesa"
                    type="text"
                  />

                </Typography>
              </CustomTabPanel>
            </Box>

            <div style={{ borderTop: `solid 1px #DCDBDB`, position: "fixed", bottom: "0", right: "0", left: isMobile ? 0 : 315, backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
              <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
                Total com entrega
              </Typography>
              <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                {valorTotalCarrinho === "0" ? (
                  <>
                    Resgate
                  </>
                ) : (
                  <>
                    R$ {valorTotalCarrinho}
                  </>
                )}
              </Typography>
              <div style={{ height: "35px" }}></div>
              <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handlePagamentos}>
                Continuar
              </Button>
            </div>
            <div style={{ height: "100px" }}></div>

          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Enderecos;