import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Dialog, Slide, Radio, Divider, Button, Skeleton, Fab, InputAdornment, IconButton, Grid, FormControl, Select, MenuItem, InputLabel, Tooltip, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AddOutlined, ArrowBackOutlined, Check, CheckBox, Close, CloseOutlined, EditCalendarOutlined, EventAvailableOutlined, Facebook, Favorite, FavoriteBorderOutlined, FilterList, Instagram, LinkOutlined, LocalOfferOutlined, PrintOutlined, QueryBuilderOutlined, RemoveOutlined, SearchOutlined, SettingsBackupRestoreOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { createNewTheme, dateToTimestamp, formatarData, toReal, traduzirMes } from '../../functions/utils';

import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HistoricoPedidos({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [filtrando, setFiltrando] = useState(false);
  const [itemsNovos, setItemsNovos] = useState();
  const [openModalFiltros, setOpenModalFiltros] = useState(false);
  const [items, setItems] = useState();
  const [pedidoSelecionado, setPedidoSelecionado] = useState("");
  const [filtroPeriodoDe, setFiltroPeriodoDe] = useState(null);
  const [filtroPeriodoAte, setFiltroPeriodoAte] = useState(null);
  const [tipoTaxa, setTipoTaxa] = useState("p");
  const [tipoDesconto, setTipoDesconto] = useState("p");
  const [taxa, setTaxas] = useState("");
  const [desconto, setDesconto] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [valorTotal, setValorTotal] = useState("");
  const [valorDescontos, setValorDescontos] = useState("");
  const [valorTaxas, setValorTaxas] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [dataInicialTs, setDataInicialTs] = useState("");
  const [dataFinalTs, setDataFinalTs] = useState("");
  const [openModalItem, setOpenModalItem] = useState(false);
  const [filtro, setFiltro] = useState("todos");
  const [pesquisar, setPesquisar] = useState("");
  const [urlBase, setUrlBase] = useState("");

  const navigate = useNavigate();

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleCloseItem = () => {
    getPedidos(dataInicialTs, dataFinalTs);
    setOpenModalItem(false);
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleNovoPedido = (title) => {
    onChange("", "cardapio");
    Cookies.set('bCkPg', "entrega", { expires: 7 });
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    const dataAtual = new Date();
    dataAtual.setHours(23, 59, 59, 999);
    const dataSeteDiasAtras = new Date();
    dataSeteDiasAtras.setDate(dataSeteDiasAtras.getDate());
    dataSeteDiasAtras.setHours(0, 0, 0, 0);

    const dateAtual = new Date(dataAtual.getTime());
    const dateSeteDiasAtras = new Date(dataSeteDiasAtras.getTime());
    setDataInicial(formatarData(dateSeteDiasAtras))
    setDataFinal(formatarData(dateAtual))
    const timestampDataAtual = parseInt(dataAtual.getTime() / 1000);
    const timestampDataSeteDiasAtras = parseInt(dataSeteDiasAtras.getTime() / 1000);
    setDataInicialTs(timestampDataSeteDiasAtras)
    setDataFinalTs(timestampDataAtual)
    getPedidos(timestampDataSeteDiasAtras, timestampDataAtual);
  }, []);

  const getPedidos = (inicio, fim) => {
    const idestabelecimento = Cookies.get("Md");
    const urlbase = Cookies.get("UBs");
    setUrlBase(urlbase);
    axios.post(`${urlbase}/pedidos-f.php`, {
      idestabelecimentos: idestabelecimento,
      datainicial: inicio,
      datafinal: fim + 86399
    })
      .then(response => {
        response = response.data
        if (!response) {
          setItems([]);
          setItemsNovos([]);
          handleCloseLoading();
          return
        }

        let lastMesa, lastPedido, pedidos = []

        for (let user of response) {
          if (user.nomecliente.includes("--")) {
            // console.log(user.nomecliente)
            const arrMesas = user.nomecliente.split("--");
            const numeroMesa = parseInt(arrMesas[0]);
            const nomeCliente = arrMesas[2].charAt(0).toUpperCase() + arrMesas[2].slice(1).toLowerCase();

            if (lastMesa != numeroMesa || user.nomecliente != lastPedido) {
              user.isFirst = true;
              lastMesa = numeroMesa;
              lastPedido = user.nomecliente;
              user.numeroMesa = numeroMesa
              user.nomeClienteMesa = nomeCliente
              pedidos.push(user)
            }
          } else {
            user.isFirst = true;
            pedidos.push(user)
          }
        }

        setItems(pedidos);
        setItemsNovos(pedidos)
        handleCloseLoading();
      })
      .catch(error => {
        handleCloseLoading();
        handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
      });
  };

  const handleAtualizarPedido = (event, produto, status) => {
    console.log(produto)
    if (produto[0]) {
      produto = produto[0]
    }
    event.stopPropagation();
    let arrHora
    const timestampAtual = Math.floor(new Date().getTime() / 1000);
    let demaishoras = produto.demaishoras
    demaishoras ? arrHora = demaishoras.split(",") : arrHora = (",,").split(",");
    switch (status) {
      case "P":
        arrHora[0] = timestampAtual;
        break;
      case "E":
        arrHora[1] = timestampAtual;
        break;
      case "F":
        arrHora[2] = timestampAtual;
        break;
    }
    demaishoras = (`${arrHora[0]},${arrHora[1]},${arrHora[2]}`).replaceAll("undefined", "")
    const post_url = urlBase + "/editar-pedido.php";
    const json = JSON.stringify({
      idpedidos: produto.nomecliente.includes("--") ? produto.nomecliente : produto.idpedidos,
      status: status,
      demaishoras: demaishoras
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel restaurar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            handleSnack("Pedido restaurado com sucesso.");
            setOpenModalItem(false);
            onChange("", "updateNot")
            getPedidos(dataInicialTs, dataFinalTs);
          } else {
            handleSnack("Não foi possivel restaurar o seu pedido, tente novamente.");
          }
        }
      })
  }

  const handlePrint = () => {
    window.electronListener.imprimirPedidoManual(urlBase, pedidoSelecionado[0].nomecliente.includes("--") ? pedidoSelecionado[0].nomecliente : pedidoSelecionado[0].idpedidos, Cookies.get("tmFi"));
  };

  const handleItem = (event, produto) => {
    event.stopPropagation();
    const post_url = urlBase + "/pedido.php?idpedidos=" + (produto.nomecliente.includes("--") ? produto.nomecliente : produto.idpedidos);

    axios.post(post_url)
      .then(resp => {
        const response = resp.data
        console.log(response)
        if (response[0].nomecliente.includes("--")) {
          const arrMesas = response[0].nomecliente.split("--");
          const numeroMesa = parseInt(arrMesas[0]);
          const nomeCliente = arrMesas[2].charAt(0).toUpperCase() + arrMesas[2].slice(1).toLowerCase();
          response[0].numeroMesa = numeroMesa
          response[0].nomeClienteMesa = nomeCliente
        }
        setPedidoSelecionado(response)
        setFormaPagamento(response[0].formapagamento.toUpperCase())
        let valorTotal = 0
        for (const dados of response) {
          valorTotal += parseFloat(dados.valor.replaceAll(".", "").replaceAll(",", "."))
        }
        let tservico, tdesconto, vservico, vdesconto
        if (response[0].servico) {
          const arr = response[0].servico.split("--")
          tservico = arr[0]
          vservico = arr[1]
        } else {
          tservico = "p"
          vservico = "0"
        }
        if (response[0].desconto) {
          const arr = response[0].desconto.split("--")
          tdesconto = arr[0]
          vdesconto = arr[1]
        } else {
          tdesconto = "p"
          vdesconto = "0"
        }
        setTipoTaxa(tservico)
        setTipoDesconto(tdesconto)
        setTaxas(vservico)
        setDesconto(vdesconto)
        updateTaxaDesconto(tservico, tdesconto, vservico, vdesconto, toReal(valorTotal))

        setSubTotal(toReal(valorTotal))
      })
    setOpenModalItem(true)
  };

  const handlePesquisar = (event) => {
    setItemsNovos(items.filter(item => item.data.toLowerCase().includes(event.target.value.toLowerCase()) || item.nomecliente.toLowerCase().includes(event.target.value.toLowerCase()) || item.endereco.toLowerCase().includes(event.target.value.toLowerCase())))
    setPesquisar(event.target.value);
  };

  const handleFiltro = (event) => {
    console.log(items)
    switch (event.target.value) {
      case "todos":
        setItemsNovos(items)
        break;
      case "entregas":
        setItemsNovos(items.filter(item => item.endereco !== "Retirada"))
        break;
      case "mesas":
        setItemsNovos(items.filter(item => item.nomecliente.toLowerCase().includes("--")))
        break;
      case "retiradas":
        setItemsNovos(items.filter(item => !item.nomecliente.toLowerCase().includes("--") && item.endereco === "Retirada"))
        break;
    }

    setFiltro(event.target.value);
  };

  const updateTaxaDesconto = (tipot, tipod, valort, valord, sbTotal) => {
    if (!sbTotal) {
      sbTotal = subTotal
    }
    let novoValorTotal = calculaValorTotalPedido(sbTotal, tipot, tipod, valort, valord);
    setValorTotal(novoValorTotal)
  }

  const calculaValorTotalPedido = (valorTotal, tipoTaxa, tipoDesconto, taxa, desconto) => {
    const opcaoTaxa = tipoTaxa
    const opcaoDesconto = tipoDesconto

    let valorTaxa = parseFloat(taxa.replaceAll(".", "").replaceAll(",", "."))
    let valorDesconto = parseFloat(desconto.replaceAll(".", "").replaceAll(",", "."))
    isNaN(valorTaxa) ? valorTaxa = 0 : console.log("-- DEBUG --")
    isNaN(valorDesconto) ? valorDesconto = 0 : console.log("-- DEBUG --")
    if (opcaoTaxa == "r") {
      setValorTaxas(valorTaxa !== 0 ? toReal(valorTaxa) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) + valorTaxa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorTaxas(valorTaxa !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorTaxa / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 + (valorTaxa / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    if (opcaoDesconto == "r") {
      setValorDescontos(valorDesconto !== 0 ? toReal(valorDesconto) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) - valorDesconto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorDescontos(valorDesconto !== 0 ? toReal(parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (valorDesconto / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(".", "").replaceAll(",", ".")) * (1 - (valorDesconto / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return valorTotal
  }

  const getPedidosRealizados = (detalhes) => {
    const detalhesArray = JSON.parse(atob(detalhes));
    let typographies = [];
    for (const detalhe of detalhesArray) {
      typographies.push(
        <Typography style={{ padding: "0px 20px 10px 20px", marginTop: "-8px", alignItems: "center", fontSize: "16px" }}>
          {detalhe.quantidade}x {detalhe.nome}<br />
          <strong style={{ color: "#94a3b8", fontWeight: "normal", fontSize: "14px" }}>Observações: {detalhe.observacoes ? detalhe.observacoes : "Não há observações"}</strong><br />
          {getAdicionais(detalhe.adicionais)}
          <strong style={{ color: "#333", fontSize: "16px" }}>
            Subtotal: R$ {detalhe.valorTotal}
          </strong>
          <div style={{ height: "10px" }}></div>
        </Typography>
      );
    }

    return typographies;
  };

  const getAdicionais = (adicionais) => {
    return adicionais.map((detalhe, index) => (
      <>
        <strong key={index} style={{ color: "#94a3b8", fontSize: "14px" }}>
          + {detalhe.nome ? detalhe.nome : detalhe.nomeOpcional} R$ {detalhe.valor ? detalhe.valor : detalhe.valorOpcional}
        </strong><br />
      </>
    ));
  };

  const formataData = (data) => {
    const value = data.replace(/\D/g, '');
    const formattedValue = value
      .replace(/^(\d{2})(\d{0,2})/, '$1/$2')
      .replace(/^(\d{2})\/(\d{2})(\d{0,4})/, '$1/$2/$3');
    if (formattedValue.charAt(formattedValue.length - 1) === "/") {
      return formattedValue.slice(0, -1) + "";
    }
    return formattedValue
  }

  const handlePeriodoInicial = (event) => {
    const dataFormatada = formataData(event.target.value)
    setDataInicial(dataFormatada);
  };

  const handlePeriodoFinal = (event) => {
    const dataFormatada = formataData(event.target.value)
    setDataFinal(dataFormatada);
  };

  const handleAtualizarHistorico = () => {
    handleOpenLoading();
    setOpenModalFiltros(false)
    setFiltrando(true)
    getPedidos(filtroPeriodoDe.unix(), filtroPeriodoAte.unix())
  };

  const getDatahora = (data) => {
    if (data) {
      let [dia, mes, a, restante] = data.split(" ");
      mes = traduzirMes(mes);
      return `${dia} ${mes} - ${restante}`;
    }
    return ""
  };

  const handleOpenFiltros = (event) => {
    setOpenModalFiltros(true);
  };

  const getDataFiltro = (filtro) => {
    switch (filtro) {
      case "de":
        return filtroPeriodoDe.format('DD/MM/YYYY')
      case "ate":
        return filtroPeriodoAte.format('DD/MM/YYYY')
    }
  };

  const handleCancelarFiltros = () => {
    const dataAtual = new Date();
    dataAtual.setHours(23, 59, 59, 999);
    const dataSeteDiasAtras = new Date();
    dataSeteDiasAtras.setDate(dataSeteDiasAtras.getDate());
    dataSeteDiasAtras.setHours(0, 0, 0, 0);

    const dateAtual = new Date(dataAtual.getTime());
    const dateSeteDiasAtras = new Date(dataSeteDiasAtras.getTime());
    setDataInicial(formatarData(dateSeteDiasAtras))
    setDataFinal(formatarData(dateAtual))
    const timestampDataAtual = parseInt(dataAtual.getTime() / 1000);
    const timestampDataSeteDiasAtras = parseInt(dataSeteDiasAtras.getTime() / 1000);
    setDataInicialTs(timestampDataSeteDiasAtras)
    setDataFinalTs(timestampDataAtual)
    getPedidos(timestampDataSeteDiasAtras, timestampDataAtual);
    setFiltrando(false)
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Dialog
        open={openModalFiltros}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 640 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          <FilterList />&nbsp;&nbsp;Filtrar
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={() => setOpenModalFiltros(false)}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ overflowX: "hidden" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>De</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoDe}
                  onChange={(newValue) => setFiltroPeriodoDe(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: "100%" }}>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Até</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  value={filtroPeriodoAte}
                  onChange={(newValue) => setFiltroPeriodoAte(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setOpenModalFiltros(false)} variant="outlined" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 6 }}>Cancelar</Button>
          <Button variant="contained" size="large" style={{ fontSize: "16px", fontWeight: "500", textTransform: "none", margin: 4 }} onClick={handleAtualizarHistorico}>Filtrar</Button>
        </DialogActions>
      </Dialog>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
              style={{ zIndex: "10001" }}
            />

            <Dialog
              open={openModalItem}
              onClose={handleCloseItem}
              TransitionComponent={Transition}
              style={{ background: "rgba(0,0,0,0.7)" }}
            >
              {pedidoSelecionado && (
                <>
                  <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: 20, display: "flex", alignItems: "center", width: "600px", fontSize: 22 }}>
                    <strong style={{ color: "#333", cursor: "pointer" }}>
                      #{pedidoSelecionado[0].data}
                    </strong>
                    <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(pedidoSelecionado[0].datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px", marginRight: 10 }} />
                    <CloseOutlined variant='outlined' style={{ float: "right", width: 30, cursor: "pointer" }} onClick={() => setOpenModalItem(false)} />
                  </Typography>
                  <Divider />
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Dados do cliente</strong>
                  </Typography>
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px", marginTop: "-18px" }}>
                    <strong style={{ color: "#333", fontWeight: "normal" }}>{pedidoSelecionado[0].numeroMesa ? pedidoSelecionado[0].nomeClienteMesa : pedidoSelecionado[0].nomecliente}</strong>
                  </Typography>
                  <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Detalhes do pedido</strong>
                  </Typography>

                  {pedidoSelecionado.map((produto) => (
                    <>
                      {getPedidosRealizados(produto.detalhes)}
                    </>
                  ))}

                  <Divider style={{ marginLeft: 10, marginRight: 10, marginTop: "-10px" }} />
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Detalhes do pagamento</strong>
                  </Typography>
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-18px" }}>
                    <strong style={{ color: "#333", fontWeight: "normal" }}>{formaPagamento}</strong>
                  </Typography>

                  <Divider style={{ marginLeft: 10, marginRight: 10 }} />

                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                    <strong style={{ color: "#333" }}>Subtotal</strong>
                    <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {subTotal}</strong>
                  </Typography>

                  {pedidoSelecionado.taxa && parseFloat(pedidoSelecionado.taxa.replaceAll(".", "").replaceAll(",", ".")) && (
                    <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                      <strong style={{ color: "#333" }}>Taxa de entrega</strong>
                      <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {pedidoSelecionado.taxa}</strong>
                    </Typography>
                  )}

                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                    <strong style={{ color: "#333" }}>Taxa de serviço</strong>
                    <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTaxas}</strong>
                  </Typography>
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                    <strong style={{ color: "#333" }}>Desconto</strong>
                    <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorDescontos}</strong>
                  </Typography>
                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-15px" }}>
                    <strong style={{ color: "#333" }}>Valor total</strong>
                    <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTotal}</strong>
                  </Typography>

                  <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                    <Button disableElevation startIcon={<PrintOutlined />} variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={handlePrint}>Imprimir</Button>
                    <Button disableElevation startIcon={<SettingsBackupRestoreOutlined />} variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleAtualizarPedido(event, pedidoSelecionado, "P")}>Restaurar pedido</Button>
                  </Typography>
                  <div style={{ height: "20px" }}></div>
                </>
              )}
            </Dialog>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Meus histórico de pedidos</Typography>
              <div style={{ marginLeft: "auto", marginRight: 30 }}>
                <Tooltip title={`Filtrar seus pedidos`}>
                  <IconButton onClick={handleOpenFiltros} size="medium" color="inherit" style={{ marginLeft: "auto", borderRadius: 8, width: 48, height: 48 }}>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {filtrando &&
              <Grid item xs={12} sm={12} md={12} style={{ padding: 0 }}>
                <div style={{ height: 20 }} />
                <Typography style={{ fontWeight: "500", fontSize: 14, color: "#464545", background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                  <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                    <Grid item>
                      Limpar filtros
                    </Grid>
                    <Grid item>
                      <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>De {getDataFiltro("de")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                    </Grid>
                    <Grid item>
                      <Chip label={<Typography style={{ fontSize: 14, display: "flex", alignItems: "center" }}>Até {getDataFiltro("ate")} <div style={{ width: 8 }} /> <Close style={{ cursor: "pointer" }} onClick={handleCancelarFiltros} /></Typography>} style={{ backgroundColor: "#1B1B1B", color: "#FFF", borderRadius: 8 }} />
                    </Grid>
                  </Grid>
                </Typography>
                <div style={{ height: 10 }} />
              </Grid>
            }

            <div style={{ height: "20px" }}></div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }} variant="outlined" fullWidth
                value={pesquisar}
                placeholder="Buscar por (Número do Pedido, Nome ou Endereço)"
                onChange={handlePesquisar} />
              <div style={{ width: "30px" }}></div>
            </div>


            {/* <Typography style={{ display: "flex", alignItems: "center", fontSize: "16px", position: "absolute", right: 700, top: 70, width: "130px" }}>
        <FormControl fullWidth>
          <InputLabel>Filtrar por pedidos</InputLabel>
          <Select
            label="Forma de pagamento"
            value={filtro}
            onChange={handleFiltro}
          >
            <MenuItem value={"todos"}>
              Todos
            </MenuItem>
            <MenuItem value={"entregas"}>
              Entregas
            </MenuItem>
            <MenuItem value={"mesas"}>
              Mesas
            </MenuItem>
            <MenuItem value={"retiradas"}>
              Retiradas
            </MenuItem>
          </Select>
        </FormControl>
      </Typography> */}

            {/* <div style={{ display: 'flex', alignItems: "center", justifyContent: "right", position: "absolute", right: 16, top: 70 }}>
        <TextField id="outlined-basic" label="Data inicial do período"
          inputProps={{ maxLength: 10 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EditCalendarOutlined />
              </InputAdornment>
            )
          }} variant="outlined" value={dataInicial} onChange={handlePeriodoInicial} />
        <Typography style={{ fontSize: 14, fontWeight: "bold", padding: "0px 10px 0px 10px" }}>até</Typography>
        <TextField id="outlined-basic" label="Data final do período"
          inputProps={{ maxLength: 10 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EditCalendarOutlined />
              </InputAdornment>
            ),
          }} variant="outlined" value={dataFinal} onChange={handlePeriodoFinal} />
        <Button disableElevation endIcon={<SearchOutlined />} variant="contained" style={{ fontWeight: "bold", textTransform: "capitalize", marginLeft: "10px", color: "#fff" }} onClick={handleAtualizarHistorico}>Buscar</Button>
      </div> */}

            <div style={{ display: 'flex', position: "absolute", left: 0, right: 0, bottom: 0, top: 150 }}>
              <div style={{ flex: 1, backgroundColor: '#FF5733', marginRight: 10, height: '100vh', border: "solid white 16px", borderRightWidth: "8px", overflowY: "auto" }}>
                <Typography style={{ color: "white", padding: "10px", fontSize: "18px", fontWeight: "bold" }}>
                  Histórico
                </Typography>
                <div style={{ height: "20px" }}></div>

                <Grid container spacing={2}>
                  {itemsNovos ? (
                    <>
                      {itemsNovos.map((produto) => (
                        <>
                          {produto.isFirst && (
                            <Grid item xs={12} sm={4}>
                              <Card style={{ width: "95%", marginLeft: "2.5%", cursor: "pointer" }} onClick={(event) => handleItem(event, produto)}>
                                <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "10px 20px 10px 20px", display: "flex", alignItems: "center" }}>
                                  <strong style={{ color: "#333" }}>#{produto.data}</strong>
                                  <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} />
                                </Typography>
                                <Divider />
                                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                                  <strong style={{ color: "#333" }}>Dados do cliente</strong>
                                  <strong style={{ color: "#333", marginLeft: "auto" }}>Telefone</strong>
                                </Typography>
                                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                                  <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.numeroMesa ? produto.nomeClienteMesa : produto.nomecliente}</strong>
                                  <strong style={{ color: "#333", fontWeight: "normal", marginLeft: "auto" }}>{produto.telefonecliente ? produto.telefonecliente : "Não informado"}</strong>
                                </Typography>

                                <Divider style={{ marginLeft: 10, marginRight: 10 }} />
                                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-5px" }}>
                                  <strong style={{ color: "#333" }}>{produto.numeroMesa ? <>Número da mesa</> : <>Dados da entrega</>}</strong>
                                </Typography>
                                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "13px", marginTop: "-18px" }}>
                                  <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.numeroMesa ? produto.numeroMesa : produto.endereco}</strong>
                                </Typography>
                                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                                  <Button disableElevation startIcon={<SettingsBackupRestoreOutlined />} variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleAtualizarPedido(event, produto, "P")}>Restaurar pedido</Button>
                                </Typography>
                              </Card>
                              <div style={{ height: "12px" }}></div>
                            </Grid>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </div>

            <div style={{ height: "150px" }}></div>

          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default HistoricoPedidos;