import { Container } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useState } from 'react';
import logoImage from "../imagens/logo-mely-white.png";

const loadingContainerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
  width: '300px',
  height: 'auto',
  overflow: 'hidden',
};

const loadingImageStyle = {
  width: '100%',
  height: '100%',
  animation: 'progress 2s linear infinite',
  opacity: 1,
  clipPath: 'inset(0 100% 0 0)',
};

const progressKeyframes = `
  @keyframes progress {
    0% {
      clip-path: inset(0 100% 0 0); // Começa escondendo a imagem
    }
    100% {
      clip-path: inset(0 0 0 0); // Mostra a imagem inteira
    }
  }
`;

function LoadingComponent({ open, handleClose }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <div style={loadingContainerStyle}>
      <style>{progressKeyframes}</style>
      <img
        src={logoImage}
        alt="Loading..."
        style={loadingImageStyle}
      />
    </div>
      {/* <CircularProgress color="inherit" style={{ width: 100, height: 100}} /> */}
      {/* <img src={logoImage} style={loadingImageStyle} /> */}
    </Backdrop>
  );
}

export default LoadingComponent;
