import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, Typography, IconButton, Button, Grid, Stepper, Step, StepLabel, Divider } from '@mui/material';
import { AccessTimeOutlined, AddOutlined, ArrowBackOutlined, DeleteOutline, HomeOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, RemoveOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { toReal, createNewTheme } from '../../functions/utils';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { ColorlibConnector, ColorlibStepIcon } from '../../components/utils';

const steps = ["Cardápio", "Sacola", "Entrega", "Pagamento", "Revisar"];

function Carrinho({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const state = useLocation().state;
  const page = state ? state.page : 'home';
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [carrinho, setCarrinho] = useState([]);
  const [urlBase, setUrlBase] = useState("");
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");

  const handleBack = () => {
    onChange("", "cardapio-venda");
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    onChange("", "cardapio-venda");
  };

  const handleClear = () => {
    Cookies.remove('crU');
    Cookies.remove('bCrt');
    Cookies.remove('vPt');
    onChange("", "cardapio-venda");
  };

  const handleEnderecos = () => {
    onChange("", "enderecos");
  };

  const handleAddQtd = (id) => {
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      return;
    }

    for (const produto of carrinho) {
      if (produto.id === id) {
        produto.quantidade += 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  const handleRmQtd = (id) => {
    let count = 0
    for (const produto of carrinho) {
      if (produto.id === id) {
        if (produto.quantidade === 1) {
          carrinho.splice(count, 1);
          continue
        }
        produto.quantidade -= 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
      count++
    }
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      handleClear();
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  useEffect(() => {

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    const url = Cookies.get('UBs');
    setUrlBase(url);
    updateCart();

  }, []);

  const updateCart = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      console.log(currentCarrinho)
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho) {
        Cookies.remove('crU');
        handleBack()
      }
      Cookies.set('vlT', toReal(valorTotal), { expires: 7 });
      setValorTotalCarrinho(toReal(valorTotal))
    }
  }

  const handleChangeStepper = (label) => {
    switch (label) {
      case "Cardápio":
        onChange("", "cardapio-venda")
        break;
    }
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>

            <div style={{ height: "16px" }}></div>

            <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label} onClick={() => handleChangeStepper(label)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <div style={{ height: "16px" }}></div>
            <Divider />
            <div style={{ height: "16px" }}></div>

            <div style={{ display: "flex", alignItems: "center", paddingTop: 4 }}>
              <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Itens na sacola</Typography>
              <div style={{ marginLeft: "auto", paddingRight: 16 }}>
                <Button variant="contained" style={{ textTransform: "capitalize", fontWeight: "700" }} startIcon={<AddOutlined />} onClick={handleBack}>Adicionar mais itens</Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" color={"error"} style={{ textTransform: "capitalize", fontWeight: "700" }} startIcon={<DeleteOutline />} onClick={handleClear}> Limpar Sacola</Button>
              </div>
            </div>

            <div style={{ height: "8px" }}></div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {carrinho.map((produto, index) => (
                    <>
                      {index % 2 === 0 && (
                        <>
                          <ListItem key={produto.id} alignItems="flex-start">
                            <ListItemAvatar>
                              <img src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.id}_0.png`} style={{ borderRadius: "4px", height: "56px", width: "56px" }} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                              }} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={produto.nome}
                              primaryTypographyProps={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", paddingLeft: "16px" }}
                              secondary={
                                <React.Fragment>
                                  <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "16px" }}>
                                    {produto.descricao}
                                    {(produto.adicionais).map((adicional) => (
                                      <>
                                        <b style={{ fontSize: "12px" }}><br />+ {adicional.quantidadeOpcional}x {adicional.nomeOpcional}</b>
                                      </>
                                    ))}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                            <Typography style={{ float: "left", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                              R$ {produto.valorTotal}
                            </Typography>

                            <div style={{ position: "absolute", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                              <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{produto.quantidade}</p>
                              {produto.quantidade === 1 ? (
                                <DeleteOutline style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                              ) : (
                                <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                              )}
                              <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleAddQtd(produto.id)} />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {carrinho.map((produto, index) => (
                    <>
                      {index % 2 !== 0 && (
                        <>
                          <ListItem key={produto.id} alignItems="flex-start">
                            <ListItemAvatar>
                              <img src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.id}_0.png`} style={{ borderRadius: "4px", height: "56px", width: "56px" }} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                              }} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={produto.nome}
                              primaryTypographyProps={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", paddingLeft: "16px" }}
                              secondary={
                                <React.Fragment>
                                  <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "16px" }}>
                                    {produto.descricao}
                                    {(produto.adicionais).map((adicional) => (
                                      <>
                                        <b style={{ fontSize: "12px" }}><br />+ {adicional.quantidadeOpcional}x {adicional.nomeOpcional}</b>
                                      </>
                                    ))}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                            <Typography style={{ float: "left", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                              R$ {produto.valorTotal}
                            </Typography>

                            <div style={{ position: "absolute", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                              <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{produto.quantidade}</p>
                              {produto.quantidade === 1 ? (
                                <DeleteOutline style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                              ) : (
                                <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                              )}
                              <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleAddQtd(produto.id)} />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </List>
              </Grid>
            </Grid>

            <div style={{ borderTop: `solid 1px #DCDBDB`, position: "fixed", bottom: "0", right: "0", left: isMobile ? 0 : 315, backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
              <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
                Total
              </Typography>
              <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                {valorTotalCarrinho === "0" ? (
                  <>
                    Resgate
                  </>
                ) : (
                  <>
                    R$ {valorTotalCarrinho}
                  </>
                )}
              </Typography>
              <div style={{ height: "35px" }}></div>
              <Button fullWidth variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handleEnderecos}>
                Continuar
              </Button>
            </div>
            <div style={{ height: "200px" }}></div>


          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Carrinho;