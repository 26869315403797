import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { List, ListItem, ListItemButton, ListItemText, Menu, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Dialog, Slide, Radio, Divider, Button, Skeleton, Fab, IconButton, Grid, Paper, DialogTitle, Stepper, Step, StepLabel, InputAdornment } from '@mui/material';
import { AccessTimeOutlined, AddOutlined, ArrowBackOutlined, Check, CheckBox, CloseOutlined, Facebook, Favorite, FavoriteBorderOutlined, Instagram, LinkOutlined, LocalOfferOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, RemoveOutlined, SearchOutlined, ShareOutlined, ShoppingBagOutlined, Star, Telegram, TimerOutlined, VolumeOffOutlined, VolumeUpOutlined, WhatsApp } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { createNewTheme, toReal } from '../../functions/utils';

import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { Accordion, AccordionDetails, AccordionSummary, ColorlibConnector, ColorlibStepIcon, MaterialUISwitch } from '../../components/utils';

const steps = ["Cardápio", "Sacola", "Entrega", "Pagamento", "Revisar"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CardapioVenda({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemShare, setItemShare] = useState("");
  const [isPizza, setIsPizza] = useState(false);
  const [segundoSabor, setSegundoSabor] = useState(false);
  const [terceiroSabor, setTerceiroSabor] = useState(false);
  const [quartoSabor, setQuartoSabor] = useState(false);
  const [tamanhoPizza, setTamanhoPizza] = useState("");
  const [saboresPizza, setSaboresPizza] = useState("");
  const [openModalItem, setOpenModalItem] = useState(false);
  const [showImgItem, setShowImgItem] = useState(false);
  const [segment, setSegment] = useState("Todos");
  const [comentario, setComentario] = useState('');
  const [qtdItemsAdd, setQtdItemsAdd] = useState(1);
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [nota, setNota] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [items, setItems] = useState(null);
  const [itemSelected, setItemSelected] = useState([]);
  const [opcionaisSelected, setOpcionaisSelected] = useState({});
  const [adicionaisSelected, setAdicionaisSelected] = useState({});
  const [limiteOpcionaisSelected, setLimiteOpcionaisSelected] = useState({});
  const [carrinho, setCarrinho] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [destaques, setDestaque] = useState(null);
  const [urlBase, setUrlBase] = useState("");
  const [nome, setNome] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [valorTotalItem, setValorTotalItem] = useState(0);
  const [valorMinimo, setValorMinimo] = useState(false);
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [favoritos, setFavoritos] = useState(null);
  const [enableForBuy, setEnabledForBuy] = useState(true);
  const [textColor, setTextColor] = useState('#FFF');
  const [expandedArr, setExpandedArr] = useState([]);

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const paperRef = useRef(null);
  const scrollTo = useRef(null);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleExpanded = (panel) => (event, newExpanded) => {

    setExpandedArr((prevExpandedArr) => ({
      ...prevExpandedArr,
      [panel]: newExpanded !== undefined ? newExpanded : true,
    }));

    const section = document.getElementById(panel)?.offsetTop - 150 || 0;

    setTimeout(() => {
      window.scrollTo({
        top: section,
        behavior: 'smooth',
      });

    }, 1000)
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleAddQtdBtn = (produto) => {
    if (limiteOpcionaisSelected && parseInt(produto.limite) > 0) {
      if (limiteOpcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()]) {
        if (parseInt(produto.limite) <= limiteOpcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()].quantidadeSelecionada) {
          return <></>
        }
      }
    }
    return <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} onClick={() => handleAdicionalSelect(produto.nome, produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase(), "add")} />
  };

  const handleAddQtd = () => {
    const newValue = parseInt(qtdItemsAdd) + 1
    setQtdItemsAdd(newValue);
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, newValue, tamanhoPizza, saboresPizza, segundoSabor, terceiroSabor, quartoSabor, items, isPizza);
  };

  const handleRmQtd = () => {
    let newValue = parseInt(qtdItemsAdd) - 1
    if (newValue === 0) {
      newValue = 1
    }
    setQtdItemsAdd(newValue);
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, newValue, tamanhoPizza, saboresPizza, segundoSabor, terceiroSabor, quartoSabor, items, isPizza);
  };

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  const handlePesquisar = (event) => {
    const response = itemsOrigim.filter(item => item.nome.toLowerCase().includes(event.target.value.toLowerCase()) || item.categoria.toLowerCase().includes(event.target.value.toLowerCase()))
    console.log(response)
    for (const produto of response) {
      produto.categoria = `${produto.categoriaNumero} - ${produto.categoriaOriginal}${produto.categoriaProduto ? ` - ${produto.categoriaProduto}` : ""}`
      let valorapartir = [], valorapartiratualizado = parseFloat(produto.valor.replace(".", "").replace(",", '.')), ultsubcategoria = "", categoria = produto.categoria, count = -1, nomeatual = produto.nome
      for (const produto of response) {
        produto.categoria = `${produto.categoriaNumero} - ${produto.categoriaOriginal}${produto.categoriaProduto ? ` - ${produto.categoriaProduto}` : ""}`
        const partes = produto.categoria.split(' - ');
        const categoriaNumero = partes[0];
        const categoriaOriginal = partes[1];
        const categoriaProduto = partes.slice(2).join(' - ');
        produto.categoriaNumero = categoriaNumero
        produto.categoriaOriginal = categoriaOriginal
        produto.categoriaProduto = categoriaProduto
        if (produto.status == "P") {
          continue;
        }
        if (produto.estoque != undefined && produto.estoque == 0) {
          continue;
        }
        if (produto.tipo != null) {
          if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
            count++
            valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
            ultsubcategoria = produto.subcategoria
          }
          if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
            valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
          }
          if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
            count++
            valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
            ultsubcategoria = produto.subcategoria
          }
          if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
            valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
          }
        }
      }

      for (const valora of valorapartir) {
        valorapartiratualizado += valora
      }
      produto.valor_apartir = valorapartiratualizado
    }

    // ajusta as categorias
    let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = "", arrPdt = []
    for (const produto of response) {
      const partes = produto.categoria.split(' - ');
      const categoriaNumero = partes[0];
      const categoriaOriginal = partes[1];
      const categoriaProduto = partes.slice(2).join(' - ');
      produto.categoriaNumero = categoriaNumero
      produto.categoriaOriginal = categoriaOriginal
      produto.categoriaProduto = categoriaProduto
      produto.subcategoriaOriginal = produto.subcategoria
      if (ultimaCategoria == "" || ultimaCategoria != produto.categoria) {
        if (!produto.tipo) {
          if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
            ultimaCategoria = produto.categoria
            ultimaSubcategoria = produto.subcategoria
            ultimoProduto = categoriaProduto
            produto.isFirst = "true"
            arrPdt.push(produto)
            continue
          } else {
            if (produto.subcategoria == "") {
              ultimaSubcategoria = produto.subcategoria
            }
            ultimaCategoria = produto.categoria
            ultimoProduto = categoriaProduto
            produto.isFirst = "true"
            arrPdt.push(produto)
            continue
          }
        } else {
          if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
            ultimaCategoria = produto.categoria
            ultimaSubcategoria = produto.subcategoria
            ultimoProduto = categoriaProduto
            produto.isFirst = "true"
            arrPdt.push(produto)
            continue
          }
        }
      } else {
        if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
          ultimaCategoria = produto.categoria
          ultimaSubcategoria = produto.subcategoria
          ultimoProduto = categoriaProduto
          produto.isFirst = "true"
          arrPdt.push(produto)
          continue
        }
      }

      if (produto.tipo) {
        const produtoEncontrado = arrPdt.find(item => item.categoriaProduto === produto.categoriaProduto && item.categoriaOriginal === produto.categoriaOriginal && item.subcategoriaOriginal === produto.subcategoriaOriginal);
        if (!produtoEncontrado) {
          produto.isFirst = "true"
        }
        arrPdt.push(produto)
      }
      ultimoProduto = categoriaProduto

      produto.categoria = ""
      produto.subcategoria = ""
    }

    setItems(response)
    setPesquisar(event.target.value);
  };

  const handleOpcionalSelect = (nomeSelect, nomeOpcional) => {
    const newOpcionaisSelectedArray = { ...opcionaisSelected };
    newOpcionaisSelectedArray[nomeOpcional] = nomeSelect;
    setOpcionaisSelected(newOpcionaisSelectedArray);
    validaOpcionais(newOpcionaisSelectedArray, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhoPizza, saboresPizza, segundoSabor, terceiroSabor, quartoSabor, items, isPizza);
  }

  const handleAdicionalSelect = (nomeSelect, nomeOpcional, calc) => {
    let quantidade = 0

    const newAdicionaisSelectedArray = { ...adicionaisSelected };

    const key = nomeOpcional.toLowerCase().replaceAll(" ", "");

    if (!(key in newAdicionaisSelectedArray)) {
      newAdicionaisSelectedArray[key] = {};
    }

    const tipoKey = nomeSelect.toLowerCase().replaceAll(" ", "");

    if (!(tipoKey in newAdicionaisSelectedArray[key])) {
      newAdicionaisSelectedArray[key][tipoKey] = {};
    }

    newAdicionaisSelectedArray[key][tipoKey].quantidade ?
      quantidade = newAdicionaisSelectedArray[key][tipoKey].quantidade : quantidade = 0
    calc === "add" ? quantidade += 1 : quantidade -= 1
    if (quantidade < 0) {
      quantidade = 0;
    }

    newAdicionaisSelectedArray[key][tipoKey].nome = nomeSelect;
    newAdicionaisSelectedArray[key][tipoKey].quantidade = quantidade;

    setAdicionaisSelected(newAdicionaisSelectedArray);
    validaOpcionais(opcionaisSelected, newAdicionaisSelectedArray, itemSelected, qtdItemsAdd, tamanhoPizza, saboresPizza, segundoSabor, terceiroSabor, quartoSabor, items, isPizza);
  }

  const handleQuantidadeAdicionais = (produto) => {
    const isAdicionaisSelectedEmpty = Object.keys(adicionaisSelected).length === 0;
    // return 0
    if (isAdicionaisSelectedEmpty) {
      return 0
    }
    if (typeof adicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] !== 'undefined') {
      if (adicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()]) {
        return adicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade ? adicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade : 0
      }
    }
    return 0
  };


  const handleShowCart = () => {
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    onChange("", "carrinho");
  };

  const resetItem = () => {
    setQtdItemsAdd(1)
    setComentario("")
  }

  const handleSegundoSabor = (segundoProduto) => {
    setSegundoSabor(segundoProduto);
    if (saboresPizza !== "meia") {
      scrollTo.current = `${segundoProduto}__TERCEIROSABOR`
    }
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhoPizza, saboresPizza, segundoProduto, terceiroSabor, quartoSabor, items, isPizza);
  }

  const handleTerceiroSabor = (terceiroproduto) => {
    setTerceiroSabor(terceiroproduto);
    if (saboresPizza !== "meia" && saboresPizza !== "3/8") {
      scrollTo.current = `${terceiroproduto}__QUARTOSABOR`
    }
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhoPizza, saboresPizza, segundoSabor, terceiroproduto, quartoSabor, items, isPizza);
  }

  const handleQuartoSabor = (quartoproduto) => {
    setQuartoSabor(quartoproduto);
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhoPizza, saboresPizza, segundoSabor, terceiroSabor, quartoproduto, items, isPizza);
  }

  const handleSabores = (sabor) => {
    setSegundoSabor("")
    setTerceiroSabor("")
    setQuartoSabor("")
    if (sabor !== "normal") {
      scrollTo.current = sabor;
    }
    setSaboresPizza(sabor);
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhoPizza, sabor, "", "", "", items, isPizza);
  }

  const handleTamanho = (tamanhos) => {
    setTamanhoPizza(tamanhos);
    setSegundoSabor("")
    setTerceiroSabor("")
    setQuartoSabor("")
    setSaboresPizza("")
    validaOpcionais(opcionaisSelected, adicionaisSelected, itemSelected, qtdItemsAdd, tamanhos, "", "", "", "", items, isPizza);
    if (!saboresPizza) {
      const section = document.getElementById("qtdSabores")?.offsetTop || 0;
      const dialogElement = document.getElementById("dialogFS");
      if (dialogElement) {
        const paperElement = dialogElement.querySelector(".MuiPaper-root");
        paperElement.scrollTo({
          top: section,
          behavior: 'smooth', // Este comportamento faz a rolagem ser suavizada
        });
      }
    }
  }

  const setPizza = (isPizza) => {
    setIsPizza(isPizza);
  }

  const handleItem = (produto, items) => {
    resetItem()
    setOpenModalItem(true);
    setItemSelected(produto);
    handleTextColor(produto)
    setOpcionaisSelected({});
    setAdicionaisSelected({});
    setTamanhoPizza("");
    setSaboresPizza("");
    setSegundoSabor("");
    setTerceiroSabor("");
    setQuartoSabor("");
    produto.categoriaOriginal.toLowerCase().includes("pizza") ? setPizza(true) : setPizza(false)
    setQtdItemsAdd(1);
    validaOpcionais({}, {}, produto, 1, "", "", "", "", "", items ? items : "", produto.categoriaOriginal.toLowerCase().includes("pizza"));
  }

  const handleCloseItem = () => {
    setOpenModalItem(false);
  };

  const handleFavoriteItem = (id) => {
    const favoritos = Cookies.get('fvUsr');
    if (favoritos) {
      if (favoritos.includes(`"${id}"`)) {
        Cookies.set('fvUsr', favoritos.replaceAll(`"${id}"`, ""), { expires: 7 });
        setFavoritos(Cookies.get('fvUsr'))
        return
      }
      Cookies.set('fvUsr', `${favoritos}"${id}"`, { expires: 7 });
      setFavoritos(Cookies.get('fvUsr'))
      return
    }
    Cookies.set('fvUsr', `"${id}"`, { expires: 7 });
    setFavoritos(Cookies.get('fvUsr'))
  };

  const handleShowImgItem = () => {
    setShowImgItem(true);
  };

  const handleHideImgItem = () => {
    setShowImgItem(false);
  };

  const getPizza = (broto) => {
    switch (saboresPizza) {
      case "meia":
        return `${broto}1/2 ${itemSelected.nome} e 1/2 ${segundoSabor.nome}`
      case "3/8":
        return `${broto}1/3 ${itemSelected.nome}, 1/3 ${segundoSabor.nome} e 1/3 ${terceiroSabor.nome}`
      case "4/8":
        return `${broto}1/4 ${itemSelected.nome}, 1/4 ${segundoSabor.nome}, 1/4 ${terceiroSabor.nome} e 1/4 ${quartoSabor.nome}`
    }

  }

  const handleAddCarrinho = () => {
    if (valorTotalCarrinho === "0") {
      handleSnack("Para adicionar novos itens ao carrinho você precisa finalizar o resgate do item!");
      return;
    }

    if (disabledButton) { return; }
    let broto = ""

    if (isPizza) {
      if (tamanhoPizza === "broto") {
        broto = "Broto "
      }
    }
    let newCarrinho = [...carrinho];
    const arrayCarrinho = {
      id: itemSelected.idprodutos,
      nome: isPizza && saboresPizza !== "normal" ? getPizza(broto) : `${broto}${itemSelected.nome}`,
      descricao: itemSelected.descricao,
      categoria: itemSelected.categoriaOriginal,
      valorOriginal: itemSelected.valor,
      valorComAdicionais: toReal(itemSelected.valorComAdicionais),
      valorTotal: toReal(itemSelected.valorTotal),
      quantidade: qtdItemsAdd,
      tipo: "",
      observacoes: comentario,
      adicionais: itemSelected.adicionais,
      limiteestoque: itemSelected.estoque,
    }
    console.log(arrayCarrinho)
    newCarrinho.push(arrayCarrinho)
    setCarrinho(newCarrinho);
    let valorTotal = 0
    for (const produto of newCarrinho) {
      valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
    }
    setValorTotalCarrinho(toReal(valorTotal))
    handleCloseItem()
  }

  const getSegundoValor = (produto) => {
    if (tamanhoPizza === "broto") {
      return produto.valor_desconto ? toReal(parseFloat(produto.valor_broto.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(produto.valor_desconto.replaceAll(".", "").replaceAll(",", "."))) : produto.valor_broto
    } else {
      return produto.valor_desconto ? toReal(parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(produto.valor_desconto.replaceAll(".", "").replaceAll(",", "."))) : produto.valor
    }
  }

  useEffect(() => {
    if (scrollTo.current) {
      let pageScroll = ""
      if (scrollTo.current === "meia" || scrollTo.current === "3/8" || scrollTo.current === "4/8") {
        pageScroll = "segundoSabor"
      }
      if (scrollTo.current.includes("__TERCEIROSABOR")) {
        pageScroll = "terceiroSabor"
      }
      if (scrollTo.current.includes("__QUARTOSABOR")) {
        pageScroll = "quartoSabor"
      }
      if (pageScroll) {
        const section = document.getElementById(pageScroll)?.offsetTop || 0;
        const dialogElement = document.getElementById("dialogFS");
        if (dialogElement) {
          const paperElement = dialogElement.querySelector(".MuiPaper-root");
          paperElement.scrollTo({
            top: section,
            behavior: 'smooth', // Este comportamento faz a rolagem ser suavizada
          });
        }
        scrollTo.current = null
      }
    }
  }, [scrollTo.current]);
  useEffect(() => {
    // handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    const favoritos = Cookies.get('fvUsr');
    setFavoritos(favoritos)
    if (!urlBase) {
      onChange("", "home");
    }
    setUrlBase(urlBase)
    setNome(mainName)
    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)
    const statusAtual = Cookies.get('sTA');
    setStatusEstabelecimento(statusAtual)
    getProdutos(urlBase);
    const nota = Cookies.get('MnT');
    setNota(nota)
    const valorMinimo = Cookies.get('vlMnm');
    setValorMinimo(valorMinimo);

    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      setValorTotalCarrinho(toReal(valorTotal))
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sections = document.querySelectorAll('.sc-mnu');

    let sectionSelected
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (scrollPosition >= sectionTop - 120) {
        sectionSelected = section.id
      }
    });
    sectionSelected ? setSegment(sectionSelected) : setSegment("Todos");
  };

  const getProdutos = (url) => {
    axios.get(`${url}/produtos-menu.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data
        let itemSelect
        // verifica o valor a partir
        for (const produto of response) {
          let valorapartir = [], valorapartiratualizado = parseFloat(produto.valor.replace(".", "").replace(",", '.')), ultsubcategoria = "", categoria = produto.categoria, count = -1, nomeatual = produto.nome
          for (const produto of response) {
            const partes = produto.categoria.split(' - ');
            const categoriaNumero = partes[0];
            const categoriaOriginal = partes[1];
            const categoriaProduto = partes.slice(2).join(' - ');
            produto.categoriaNumero = categoriaNumero
            produto.categoriaOriginal = categoriaOriginal
            produto.categoriaProduto = categoriaProduto
            if (produto.status == "P") {
              continue;
            }
            if (produto.estoque != undefined && produto.estoque == 0) {
              continue;
            }
            if (produto.tipo != null) {
              if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
                count++
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
                ultsubcategoria = produto.subcategoria
              }
              if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
              }
              if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
                count++
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
                ultsubcategoria = produto.subcategoria
              }
              if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoriaProduto == nomeatual || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
              }
            }
          }
          const idItemSelect = Cookies.get('iIts');
          if (idItemSelect === produto.idprodutos) {
            itemSelect = produto;
          }
          for (const valora of valorapartir) {
            valorapartiratualizado += valora
          }
          produto.valor_apartir = valorapartiratualizado
        }

        // ajusta as categorias
        let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = "", arrPdt = []
        for (const produto of response) {
          const partes = produto.categoria.split(' - ');
          const categoriaNumero = partes[0];
          const categoriaOriginal = partes[1];
          const categoriaProduto = partes.slice(2).join(' - ');
          produto.categoriaNumero = categoriaNumero
          produto.categoriaOriginal = categoriaOriginal
          produto.categoriaProduto = categoriaProduto
          produto.subcategoriaOriginal = produto.subcategoria
          if (ultimaCategoria == "" || ultimaCategoria != produto.categoria) {
            if (!produto.tipo) {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                arrPdt.push(produto)
                continue
              } else {
                if (produto.subcategoria == "") {
                  ultimaSubcategoria = produto.subcategoria
                }
                ultimaCategoria = produto.categoria
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                arrPdt.push(produto)
                continue
              }
            } else {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                arrPdt.push(produto)
                continue
              }
            }
          } else {
            if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
              ultimaCategoria = produto.categoria
              ultimaSubcategoria = produto.subcategoria
              ultimoProduto = categoriaProduto
              produto.isFirst = "true"
              arrPdt.push(produto)
              continue
            }
          }

          if (produto.tipo) {
            const produtoEncontrado = arrPdt.find(item => item.categoriaProduto === produto.categoriaProduto && item.categoriaOriginal === produto.categoriaOriginal && item.subcategoriaOriginal === produto.subcategoriaOriginal);
            if (!produtoEncontrado) {
              produto.isFirst = "true"
            }
            arrPdt.push(produto)
          }
          ultimoProduto = categoriaProduto

          produto.categoria = ""
          produto.subcategoria = ""
        }
        handleCloseLoading();
        setItems(response);
        setItemsOrigim(response);
        setDestaque(response.filter(item => item.destaque === 'S'));

        if (itemSelect) {
          handleItem(itemSelect, response)
          Cookies.remove('iIts');
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const validaOpcionais = (currentOpcionaisSelected, currentAdicionaisSelected, currentItemSelected, currentQtd, tamanho, sabor, segundoSabor, terceiroSabor, quartoSabor, currentItem, currentIsPizza) => {

    const newLimiteArray = { ...limiteOpcionaisSelected };

    let countValid = 0, valorAtualizado, additional = [], isSegundoSabor = false, isTerceiroSabor = false, isQuartoSabor = false, currentItems
    valorAtualizado = parseFloat(currentItemSelected.valor.replaceAll(".", "").replaceAll(",", "."))

    if (currentIsPizza) {
      if (!tamanho && valorAtualizado > 0) {
        countValid++;
      }
      if (!sabor && valorAtualizado > 0) {
        countValid++;
      }
      if (sabor === "meia" && !segundoSabor) {
        countValid++;
      }
      if (sabor === "3/8" && (!segundoSabor || !terceiroSabor)) {
        countValid++;
      }
      if (sabor === "4/8" && (!segundoSabor || !terceiroSabor || !quartoSabor)) {
        countValid++;
      }
      if (tamanho === "broto") {
        valorAtualizado = parseFloat(currentItemSelected.valor_broto.replaceAll(".", "").replaceAll(",", "."))
      }

      if (segundoSabor) {
        if (tamanho === "broto" && parseFloat(segundoSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor_broto.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(segundoSabor.valor_broto.replaceAll(".", "").replaceAll(",", "."))
          isSegundoSabor = true;
        }
        if (tamanho === "normal" && parseFloat(segundoSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(segundoSabor.valor.replaceAll(".", "").replaceAll(",", "."))
          isSegundoSabor = true;
        }
      }
      if (terceiroSabor) {
        if (tamanho === "broto" && parseFloat(terceiroSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor_broto.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(terceiroSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(segundoSabor.valor_broto.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(terceiroSabor.valor_broto.replaceAll(".", "").replaceAll(",", "."))
          isTerceiroSabor = true;
        }
        if (tamanho === "normal" && parseFloat(terceiroSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(terceiroSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(segundoSabor.valor.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(terceiroSabor.valor.replaceAll(".", "").replaceAll(",", "."))
          isTerceiroSabor = true;
        }
      }
      if (quartoSabor) {
        if (tamanho === "broto" && parseFloat(quartoSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor_broto.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(quartoSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(terceiroSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(quartoSabor.valor_broto.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(segundoSabor.valor_broto.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(quartoSabor.valor_broto.replaceAll(".", "").replaceAll(",", "."))
          isQuartoSabor = true;
        }
        if (tamanho === "normal" && parseFloat(quartoSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(currentItemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(quartoSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(terceiroSabor.valor.replaceAll(".", "").replaceAll(",", ".")) && parseFloat(quartoSabor.valor.replaceAll(".", "").replaceAll(",", ".")) > parseFloat(segundoSabor.valor.replaceAll(".", "").replaceAll(",", "."))) {
          valorAtualizado = parseFloat(quartoSabor.valor.replaceAll(".", "").replaceAll(",", "."))
          isQuartoSabor = true;
        }
      }
    }

    currentItem ? currentItems = currentItem : currentItems = items

    for (const produto of currentItems) {
      if (produto.status == "P") {
        continue;
      }
      if (produto.estoque != undefined && produto.estoque == 0) {
        continue;
      }

      if (produto.tipo != null && ((produto.categoriaProduto === currentItemSelected.nome && produto.categoriaOriginal === currentItemSelected.categoriaOriginal) || (!produto.categoriaProduto && produto.categoriaOriginal === currentItemSelected.categoriaOriginal))) {

        const nomeOpcional = produto.nome
        const descricaoOpcional = produto.descricao
        const categoriaOpcional = produto.subcategoriaOriginal
        const valorOpcional = produto.valor
        let quantidadeOpcional = 1
        if (produto.tipo.includes("true")) {
          if (!currentOpcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] && produto.tipo.includes("opcionais")) {
            countValid++
            continue
          }

          if (!currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] && produto.tipo.includes("adicionais")) {
            countValid++
            continue
          }
          if (currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()]) {
            let qtdTotalSelecionada = 0
            for (const categoriaadicional in currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()]) {
              qtdTotalSelecionada += currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][categoriaadicional].quantidade
            }
            if (qtdTotalSelecionada === 0) {
              countValid++
              continue
            }
          }

          if (currentOpcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] === produto.nome) {

            valorAtualizado += parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", "."));
            additional.push({
              nomeOpcional,
              descricaoOpcional,
              categoriaOpcional,
              valorOpcional,
              quantidadeOpcional
            })
          }

          const isAdicionaisSelectedEmpty = Object.keys(currentAdicionaisSelected).length === 0;
          if (!isAdicionaisSelectedEmpty && produto.tipo.includes("adicionais")) {
            if (typeof currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] !== 'undefined') {
              if (currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()]) {
                if (currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade === 0) {
                  continue;
                }
                quantidadeOpcional = currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade
                valorAtualizado += (parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")) * parseInt(currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade));
                additional.push({
                  nomeOpcional,
                  descricaoOpcional,
                  categoriaOpcional,
                  valorOpcional,
                  quantidadeOpcional
                })
              }
            }
          }
        } else {
          if (currentOpcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] === produto.nome) {
            valorAtualizado += parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", "."));
            additional.push({
              nomeOpcional,
              descricaoOpcional,
              categoriaOpcional,
              valorOpcional,
              quantidadeOpcional
            })
          }

          const isAdicionaisSelectedEmpty = Object.keys(currentAdicionaisSelected).length === 0;
          if (!isAdicionaisSelectedEmpty && produto.tipo.includes("adicionais")) {
            if (typeof currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] !== 'undefined') {
              if (currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()]) {
                if (currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade === 0) {
                  continue;
                }
                quantidadeOpcional = currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade
                valorAtualizado += (parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")) * parseInt(currentAdicionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()][produto.nome.replaceAll(" ", "").toLowerCase()].quantidade));
                additional.push({
                  nomeOpcional,
                  descricaoOpcional,
                  categoriaOpcional,
                  valorOpcional,
                  quantidadeOpcional
                })
              }
            }
          }
        }
      }
    }

    const arrayLimite = {};

    for (const categoriaadicional in currentAdicionaisSelected) {
      if (typeof currentAdicionaisSelected[categoriaadicional] === 'object') {
        let quantidadeTotal = 0;

        for (const item in currentAdicionaisSelected[categoriaadicional]) {
          quantidadeTotal += currentAdicionaisSelected[categoriaadicional][item].quantidade;
        }

        arrayLimite[categoriaadicional] = { quantidadeSelecionada: quantidadeTotal };
      }
    }

    setLimiteOpcionaisSelected(arrayLimite)

    if (segundoSabor && isSegundoSabor) {
      // calcula desconto
      if (segundoSabor.valor_desconto) {
        valorAtualizado = valorAtualizado - parseFloat(segundoSabor.valor_desconto.replaceAll(".", "").replaceAll(",", "."))
      }
    } else if (terceiroSabor && isTerceiroSabor) {
      // calcula desconto
      if (terceiroSabor.valor_desconto) {
        valorAtualizado = valorAtualizado - parseFloat(terceiroSabor.valor_desconto.replaceAll(".", "").replaceAll(",", "."))
      }
    } else if (quartoSabor && isQuartoSabor) {
      // calcula desconto
      if (quartoSabor.valor_desconto) {
        valorAtualizado = valorAtualizado - parseFloat(quartoSabor.valor_desconto.replaceAll(".", "").replaceAll(",", "."))
      }
    } else {
      // calcula desconto
      if (currentItemSelected.valor_desconto) {
        valorAtualizado = valorAtualizado - parseFloat(currentItemSelected.valor_desconto.replaceAll(".", "").replaceAll(",", "."))
      }
    }

    // calcula qtd
    // valorAtualizado = valorAtualizado * currentQtd

    currentItemSelected.adicionais = additional
    currentItemSelected.valorComAdicionais = valorAtualizado
    currentItemSelected.valorTotal = valorAtualizado * currentQtd
    setItemSelected(currentItemSelected)

    valorAtualizado == 0 ? setValorTotalItem("0,00") : setValorTotalItem(toReal(valorAtualizado * currentQtd))
    if (countValid > 0) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }

  const checkCategoria = (categoria) => {
    let count = 0;
    for (const item of items) {
      if (item.categoriaOriginal === categoria.categoriaOriginal && !item.tipo) {
        count += 1
      }
    }
    if (count === 1) {
      return true
    }
    return false
  };

  const handleShare = () => {
    setOpen(true);
    setItemShare("");
  };

  const handleShareItem = (id) => {
    setOpen(true);
    setItemShare(`?itS=${id}`);
  };

  const share = async (id) => {
    let url;

    switch (id) {
      case 1:
        url = `https://www.messenger.com/new/?uri=${urlBase.replaceAll("/api", "")}${itemShare}`
        window.open(url, '_blank');
        break;
      case 2:
        url = `https://api.whatsapp.com/send?text=${urlBase.replaceAll("/api", "")}${itemShare}`
        window.open(url, '_blank');
        break;
      case 3:
        url = `instagram://share?media=${urlBase.replaceAll("/api", "")}${itemShare}&caption=Veja%20só%20isso`
        window.open(url, '_blank');
        break;
      case 4:
        url = `https://telegram.me/share/url?url=${urlBase.replaceAll("/api", "")}${itemShare}&text=Veja%20só%20isso`
        window.open(url, '_blank');
        break;
      case 5:
        url = `${urlBase.replaceAll("/api", "")}${itemShare}`
        try {
          await navigator.clipboard.writeText(url);
          handleSnack("Link copiado com sucesso.")
        } catch (err) {
          handleSnack("Não foi possivel copiar o link")
        }
        break;
    }
  }

  const handleTextColor = (produto) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = `${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.idprodutos}_0.png`;

    img.addEventListener('load', function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const pixelData = ctx.getImageData(0, 0, img.width, img.height).data;
      let totalR = 0;
      let totalG = 0;
      let totalB = 0;

      for (let i = 0; i < pixelData.length; i += 4) {
        totalR += pixelData[i];
        totalG += pixelData[i + 1];
        totalB += pixelData[i + 2];
      }

      const averageR = totalR / (pixelData.length / 4);
      const averageG = totalG / (pixelData.length / 4);
      const averageB = totalB / (pixelData.length / 4);

      const brightness = (averageR * 299 + averageG * 587 + averageB * 114) / 1000;
      const newColor = brightness > 125 ? '#222' : '#FFF';
      console.log(newColor)
      setTextColor(newColor);
    });
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
              style={{ zIndex: "10001" }}
            />

            {valorMinimo && (
              <>
                <p style={{ textAlign: "center", color: "white", backgroundColor: `rgba(${colorPrimary})`, position: "absolute", top: "54px", left: 0, right: 0, padding: "6px", fontSize: "12px" }}>{valorMinimo}</p>
                <div style={{ height: "30px" }}></div>
              </>
            )}

            <Dialog
              open={openModalItem}
              onClose={handleCloseItem}
              TransitionComponent={Transition}
              sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 950 } }}
            >

              {!showImgItem && itemSelected.nome && (
                <>
                  <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
                    Adicionar no carrinho
                    <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseItem}>
                      <CloseOutlined />
                    </IconButton>
                  </DialogTitle>
                  <Divider />
                  <div style={{ maxHeight: "100vh", overflow: "auto" }}>
                    <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", padding: "16px" }}>
                      {itemSelected.nome}
                    </Typography>
                    <Typography style={{ fontSize: "14px", color: "#999999", padding: "16px", marginTop: "-25px" }} dangerouslySetInnerHTML={{ __html: itemSelected.descricao.replaceAll("\n", "<br/>") }}>
                    </Typography>

                    <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                      {itemSelected.valor_desconto && (
                        <Typography style={{ float: "left", fontSize: '16px', color: 'green', fontWeight: "bold" }} component="div">
                          <b style={{ textDecoration: "line-through", color: "#1B1B1B" }}>R$ {toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")))}</b> &nbsp; R$ {toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(itemSelected.valor_desconto.replaceAll(".", "").replaceAll(",", ".")))}
                        </Typography>
                      )}
                      {toReal(itemSelected.valor_apartir) !== toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", "."))) && itemSelected.valor_apartir !== 0 && (
                        <Typography style={{ float: "left", fontSize: '16px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
                          <b style={{ color: "#1B1B1B", fontWeight: "600" }}>A partir de</b> R$ {toReal(parseFloat(itemSelected.valor_apartir))}
                        </Typography>
                      )}

                      {itemSelected.tipovalor === "Por pessoa" && itemSelected.valor_apartir !== 0 && (
                        <Typography style={{ float: "left", fontSize: '16px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
                          R$ {toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")))} <b style={{ color: "#1B1B1B" }}>Por pessoa</b>
                        </Typography>
                      )}

                      {!itemSelected.valor_desconto && toReal(itemSelected.valor_apartir) === toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", "."))) && itemSelected.tipovalor !== "Por pessoa" && (
                        <Typography style={{ float: "left", fontSize: '16px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
                          R$ {toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")))}
                        </Typography>
                      )}

                      {enableForBuy && (
                        <div style={{ position: "relative", marginLeft: "auto", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                          <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{qtdItemsAdd}</p>
                          <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={handleRmQtd} />
                          <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={handleAddQtd} />
                        </div>
                      )}
                    </div>

                    {enableForBuy && isPizza && items && (
                      <>
                        {parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) > 0 && (
                          <>
                            <br />
                            <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }}>
                              Tamanho da Pizza
                              <Typography style={{ float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                OBRIGATÓRIO
                              </Typography>
                            </Typography>

                            <br />
                            <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                              <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>Normal</b><br />
                                <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {itemSelected.valor_desconto ? toReal(parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(itemSelected.valor_desconto.replaceAll(".", "").replaceAll(",", "."))) : itemSelected.valor}</b>
                              </Typography>
                              <Radio
                                checked={tamanhoPizza === "normal"}
                                onClick={() => handleTamanho("normal")}
                                name="tamanhopizza"
                              />
                            </Typography>
                            <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                          </>
                        )}


                        {itemSelected.valor_broto && (
                          <>
                            <br />
                            <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                              <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>Broto</b><br />
                                <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {itemSelected.valor_desconto ? toReal(parseFloat(itemSelected.valor_broto.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(itemSelected.valor_desconto.replaceAll(".", "").replaceAll(",", "."))) : itemSelected.valor_broto}</b>
                              </Typography>
                              <Radio
                                checked={tamanhoPizza === "broto"}
                                onClick={() => handleTamanho("broto")}
                                name="tamanhopizza"
                              />
                            </Typography>
                            <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                          </>
                        )}

                        {parseFloat(itemSelected.valor.replaceAll(".", "").replaceAll(",", ".")) > 0 && (
                          <>
                            <br />
                            <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }} id="qtdSabores">
                              Quantidade de Sabores
                              <Typography style={{ float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                OBRIGATÓRIO
                              </Typography>
                            </Typography>

                            <br />
                            <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                              <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>1 Sabor</b><br />
                                <b style={{ fontSize: "12px", color: "#999" }}>+ R$ 0,00</b>
                              </Typography>
                              <Radio
                                checked={saboresPizza === "normal"}
                                onClick={() => handleSabores("normal")}
                                name="saborespizza"
                              />
                            </Typography>
                            <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                            {(parseFloat(itemSelected.obs) > 1 || !itemSelected.obs) && (
                              <>
                                <br />
                                <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                  <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                    <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>2 Sabores</b><br />
                                    <b style={{ fontSize: "12px", color: "#999" }}>+ R$ 0,00</b>
                                  </Typography>
                                  <Radio
                                    checked={saboresPizza === "meia"}
                                    onClick={() => handleSabores("meia")}
                                    name="saborespizza"
                                  />
                                </Typography>
                                <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                              </>
                            )}
                            {parseFloat(itemSelected.obs) > 2 && tamanhoPizza !== "broto" && (
                              <>
                                <br />
                                <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                  <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                    <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>3 Sabores</b><br />
                                    <b style={{ fontSize: "12px", color: "#999" }}>+ R$ 0,00</b>
                                  </Typography>
                                  <Radio
                                    checked={saboresPizza === "3/8"}
                                    onClick={() => handleSabores("3/8")}
                                    name="saborespizza"
                                  />
                                </Typography>
                                <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                              </>
                            )}
                            {parseFloat(itemSelected.obs) > 3 && tamanhoPizza !== "broto" && (
                              <>
                                <br />
                                <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                  <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                    <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>4 Sabores</b><br />
                                    <b style={{ fontSize: "12px", color: "#999" }}>+ R$ 0,00</b>
                                  </Typography>
                                  <Radio
                                    checked={saboresPizza === "4/8"}
                                    onClick={() => handleSabores("4/8")}
                                    name="saborespizza"
                                  />
                                </Typography>
                                <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                              </>
                            )}
                          </>
                        )}

                        {(saboresPizza === "3/8" || saboresPizza === "4/8" || saboresPizza === "meia") && (
                          <>
                            <>
                              <br />
                              <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }} id="segundoSabor">
                                Segundo Sabor
                                <Typography style={{ float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                  OBRIGATÓRIO
                                </Typography>
                              </Typography>
                            </>
                            {itemsOrigim.map((produto) => (
                              <>
                                {!produto.tipo && (produto.categoriaProduto === itemSelected.nome || produto.categoriaOriginal === itemSelected.categoriaOriginal) && produto.idprodutos !== itemSelected.idprodutos && (
                                  <>
                                    <br />
                                    <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                      <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                        <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                        {produto.descricao && (
                                          <>
                                            <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                          </>
                                        )}
                                        <b style={{ fontSize: "12px", color: "#999" }}>R$ {getSegundoValor(produto)}</b>
                                      </Typography>
                                      <Radio
                                        checked={segundoSabor.idprodutos === produto.idprodutos}
                                        onClick={() => handleSegundoSabor(produto)}
                                        name="segundosabor"
                                      />
                                    </Typography>
                                    <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {(saboresPizza === "3/8" || saboresPizza === "4/8") && (
                          <>
                            <>
                              <br />
                              <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }} id="terceiroSabor">
                                Terceiro Sabor
                                <Typography style={{ float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                  OBRIGATÓRIO
                                </Typography>
                              </Typography>
                            </>
                            {itemsOrigim.map((produto) => (
                              <>
                                {!produto.tipo && (produto.categoriaProduto === itemSelected.nome || produto.categoriaOriginal === itemSelected.categoriaOriginal) && produto.idprodutos !== itemSelected.idprodutos && (
                                  <>
                                    {segundoSabor && segundoSabor.idprodutos !== produto.idprodutos ? (
                                      <>
                                        <br />
                                        <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                          <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                            <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                            {produto.descricao && (
                                              <>
                                                <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                              </>
                                            )}
                                            <b style={{ fontSize: "12px", color: "#999" }}>R$ {getSegundoValor(produto)}</b>
                                          </Typography>
                                          <Radio
                                            checked={terceiroSabor.idprodutos === produto.idprodutos}
                                            onClick={() => handleTerceiroSabor(produto)}
                                            name="terceirosabor"
                                          />
                                        </Typography>
                                        <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                                      </>
                                    ) : (
                                      <>
                                        <br />
                                        <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                          <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                            <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                            {produto.descricao && (
                                              <>
                                                <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                              </>
                                            )}
                                            <b style={{ fontSize: "12px", color: "#999" }}>R$ {getSegundoValor(produto)}</b>
                                          </Typography>
                                          <Radio
                                            disabled
                                            checked={terceiroSabor.idprodutos === produto.idprodutos}
                                            name="terceirosabor"
                                          />
                                        </Typography>
                                        <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        )}

                        {saboresPizza === "4/8" && (
                          <>
                            <>
                              <br />
                              <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }} id="quartoSabor">
                                Quarto Sabor
                                <Typography style={{ float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                  OBRIGATÓRIO
                                </Typography>
                              </Typography>
                            </>
                            {itemsOrigim.map((produto) => (
                              <>
                                {!produto.tipo && (produto.categoriaProduto === itemSelected.nome || produto.categoriaOriginal === itemSelected.categoriaOriginal) && produto.idprodutos !== itemSelected.idprodutos && (
                                  <>
                                    {segundoSabor && segundoSabor.idprodutos !== produto.idprodutos && terceiroSabor && terceiroSabor.idprodutos !== produto.idprodutos ? (
                                      <>
                                        <br />
                                        <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                          <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                            <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                            {produto.descricao && (
                                              <>
                                                <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                              </>
                                            )}
                                            <b style={{ fontSize: "12px", color: "#999" }}>R$ {getSegundoValor(produto)}</b>
                                          </Typography>
                                          <Radio
                                            checked={quartoSabor.idprodutos === produto.idprodutos}
                                            onClick={() => handleQuartoSabor(produto)}
                                            name="quarto"
                                          />
                                        </Typography>
                                        <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                                      </>
                                    ) : (
                                      <>
                                        <br />
                                        <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                          <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                            <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                            {produto.descricao && (
                                              <>
                                                <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                              </>
                                            )}
                                            <b style={{ fontSize: "12px", color: "#999" }}>R$ {getSegundoValor(produto)}</b>
                                          </Typography>
                                          <Radio
                                            disabled
                                            checked={quartoSabor.idprodutos === produto.idprodutos}
                                            onClick={() => handleQuartoSabor(produto)}
                                            name="quarto"
                                          />
                                        </Typography>
                                        <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        )}

                      </>
                    )}

                    {itemsOrigim && (
                      <>
                        {itemsOrigim.map((produto) => (
                          <>
                            {produto.tipo && (produto.categoriaProduto === itemSelected.nome || (produto.categoriaOriginal === itemSelected.categoriaOriginal && !produto.categoriaProduto)) && (
                              <>
                                {produto.isFirst && (
                                  <>
                                    <br />
                                    <Typography style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "12px", color: "#777", backgroundColor: "#f2f2f2", padding: 10, paddingLeft: 16 }}>
                                      {produto.subcategoriaOriginal} {parseInt(produto.limite) > 0 ? <><br /> <b style={{ fontSize: "11px" }}>Escolha até {produto.limite} opções</b></> : <></>}
                                      <Typography style={{ visibility: produto.tipo.includes("true") && enableForBuy ? "visible" : "hidden", float: "right", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", marginRight: "16px", marginBottom: "10px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                                        OBRIGATÓRIO
                                      </Typography>
                                    </Typography>
                                  </>
                                )}
                                <br />

                                <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                                  <Typography style={{ width: "100%", paddingLeft: "16px", lineHeight: "1.2" }}>
                                    <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{produto.nome}</b><br />
                                    {produto.descricao && (
                                      <>
                                        <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: produto.descricao.replaceAll("\n", "<br/>") }}></b><br />
                                      </>
                                    )}
                                    <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {produto.valor}</b>
                                  </Typography>
                                  {produto.tipo.includes("adicionais") && (
                                    <>
                                      <div style={{ position: "relative", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
                                        <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>{handleQuantidadeAdicionais(produto)}</p>
                                        <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} onClick={() => handleAdicionalSelect(produto.nome, produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase(), "rmv")} />
                                        {handleAddQtdBtn(produto)}
                                        {/* {limiteOpcionaisSelected[produto.nome.replaceAll(" ", "").toLowerCase()].quantidadeSelecionada ? limiteOpcionaisSelected[produto.nome.replaceAll(" ", "").toLowerCase()].quantidadeSelecionada : 0} */}
                                      </div>
                                    </>
                                  )}
                                  {produto.tipo.includes("opcionais") && (
                                    <Radio
                                      checked={opcionaisSelected[produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()] === produto.nome}
                                      onClick={() => handleOpcionalSelect(produto.nome, produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase())}
                                      name={produto.subcategoriaOriginal.replaceAll(" ", "").toLowerCase()}
                                    />
                                  )}
                                </Typography>
                                <Divider style={{ marginLeft: "16px", marginRight: "16px", marginTop: "10px" }} />
                              </>
                            )}
                          </>
                        ))}
                      </>
                    )}
                    <div style={{ marginBottom: "20px" }}></div>

                    {enableForBuy && (
                      <>
                        <div style={{ backgroundColor: "white", padding: "16px" }}>
                          <Typography style={{ float: "left", fontSize: "12px", fontWeight: "bold", color: "#1B1B1B" }}>
                            Observação
                          </Typography>

                          <Typography style={{ float: "right", fontSize: "12px", color: "#999" }}>
                            {comentario.length}/140
                          </Typography>
                        </div>

                        <div style={{ marginTop: "-24px", padding: "16px", marginBottom: "100px" }}>
                          <TextField
                            fullWidth
                            hiddenLabel
                            value={comentario}
                            onChange={handleComentarioChange}
                            variant="outlined"
                            size="large"
                            inputProps={{ maxLength: 140 }}
                            placeholder=""
                            type="text"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ borderTop: `solid 1px #DCDBDB`, position: "relative", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "12px 16px 12px 32px" }}>
                    {enableForBuy && (
                      <Button variant="contained" startIcon={<AddOutlined />} size="small" style={{ float: "left", fontSize: "14px", fontWeight: "bold", textTransform: "capitalize", color: "#fff" }} disabled={disabledButton} onClick={handleAddCarrinho}>
                        Adicionar
                      </Button>
                    )}
                    <Button style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: disabledButton ? "#CCC" : "#333", marginTop: "-2px" }} disabled={disabledButton}>R$ {valorTotalItem}</Button>
                  </div>
                </>
              )}

            </Dialog>

            <div style={{ height: "16px" }}></div>

            <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <div style={{ height: "16px" }}></div>
            <Divider />

            {items ? (
              <>
                <div style={{ position: "sticky", top: "74px", left: "16px", right: "16px", paddingBottom: "20px", zIndex: 1000, backgroundColor: "#FFF", paddingTop: 16, borderBottom: "solid 1px #DCDBDB" }}>
                  <div style={{ display: "flex", overflow: "auto", zIndex: 1000, backgroundColor: "#FFF" }}>
                    <Typography style={{ fontWeight: "bold", color: "#1B1B1B", paddingTop: 11 }}>Categorias</Typography>
                    <TextField
                      fullWidth
                      hiddenLabel
                      value={pesquisar}
                      onChange={handlePesquisar}
                      variant="outlined"
                      size="small"
                      style={{ width: 260, marginLeft: "auto" }}
                      placeholder="Pesquisar"
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment style={{ color: "#444" }}>
                              <SearchOutlined />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", overflow: "auto", zIndex: 1000, backgroundColor: "#FFF", paddingTop: 11, paddingBottom: 14 }}>
                    {items.map((produto) => (
                      <>
                        {!produto.tipo && produto.categoria !== "" && (
                          <>
                            <Chip variant={segment === produto.categoria ? "filled" : "outlined"} label={produto.categoriaOriginal} icon={segment === produto.categoria ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", backgroundColor: "#f7f7f7", borderColor: "#dfdfdf", color: "#1B1B1B", fontSize: 16, padding: 16 }} onClick={handleExpanded(produto.categoriaOriginal)} />
                          </>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
              </>
            )}

            <div style={{ height: "24px" }}></div>

            {/* Items */}
            {items ? (
              <>
                {items.map((produto) => (
                  !produto.tipo && produto.categoria !== "" && (
                    <>
                      <Accordion expanded={expandedArr[produto.categoriaOriginal] || false} onChange={handleExpanded(produto.categoriaOriginal)} style={{ border: "solid 1px #dfdfdf" }} id={produto.categoriaOriginal}>
                        <AccordionSummary aria-controls="panel1d-content">
                          <Typography>{produto.categoriaOriginal}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {items.map((itens, count) => (
                            itens.categoriaOriginal === produto.categoriaOriginal && !itens.tipo && (
                              <>
                                {itens.categoria === "" &&
                                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                }

                                <ListItem disablePadding style={{ backgroundColor: "" }}>
                                  <ListItemButton onClick={() => handleItem(itens)}>
                                    <CardMedia component="img" image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${itens.idprodutos}_0.png?${new Date().getTime()}`} style={{ width: 56, height: 56, backgroundColor: "#222" }} onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                                    }}
                                    />
                                    <div style={{ width: isMobile ? 4 : 18 }} />
                                    <ListItemText primary={<Typography style={{ display: "flex", alignItems: "center" }}><label style={{ color: "#999", fontSize: 16, cursor: "pointer" }}>{itens.nome}<br /><b style={{ fontSize: 14, fontWeight: "normal" }}>{itens.descricao}</b></label><br /><label style={{ marginLeft: "auto", display: "flex", alignItems: "center", color: `rgba(${colorPrimary})`, cursor: "pointer" }}> R$ {itens.valor}<div style={{ width: isMobile ? 4 : 18 }} /></label></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />

                                  </ListItemButton>
                                </ListItem>

                              </>
                            )

                          ))}
                        </AccordionDetails>
                      </Accordion>
                      <div style={{ height: 16 }} />

                    </>
                  )
                ))}
              </>
            ) : (
              <>
                <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                  <Skeleton animation="wave" height={21} width="100%" />
                </Typography>

                <div style={{ height: "12px" }}></div>
                <Card variant="outlined" sx={{ display: 'flex', borderRadius: "8px" }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>

                      <Typography component="div" style={{ fontSize: "12px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                        <Skeleton animation="wave" height={21} width="100%" />
                      </Typography>
                      <Typography style={{ fontSize: '12px', color: '#999', width: '100%', textOverflow: 'ellipsis', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box', maxHeight: '54px' }} component="div">
                        <Skeleton animation="wave" height={21} width="100%" />
                      </Typography>
                    </CardContent>
                  </Box>
                  <p style={{ textAlign: "right", minWidth: "80px", margin: 0, marginBottom: "-5px" }}>
                    <Skeleton animation="rectangular" height={80} width="80%" />
                  </p>
                </Card>
              </>
            )}
          </Grid>

          {valorTotalCarrinho && enableForBuy && (
            <div style={{ borderTop: `solid 1px #DCDBDB`, position: "fixed", bottom: "0", right: "0", left: isMobile ? 0 : 315, backgroundColor: "white", padding: "12px 16px 16px 32px" }}>
              <Button variant="contained" startIcon={<ShoppingBagOutlined />} size="small" style={{ float: "left", fontSize: "14px", fontWeight: "bold", textTransform: "capitalize", color: "#fff" }} onClick={handleShowCart}>
                Ver sacola
              </Button>
              <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: "#333", paddingTop: "4px" }}>
                {valorTotalCarrinho === "0" ? (
                  <>
                    Resgate
                  </>
                ) : (
                  <>
                    R$ {valorTotalCarrinho}
                  </>
                )}
              </Typography>
            </div>
          )}

          <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
              style: { zIndex: "10000" }
            }}
          >
            <>
              <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>
              <Typography style={{ fontSize: "20px", textAlign: "center", fontWeight: "bold" }}>Compartilhe com seus amigos!</Typography>
              <div style={{ height: "20px" }}></div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Chip icon={<WhatsApp onClick={() => share(2)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-3px" }} />} style={{ backgroundColor: "#25D366", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
                <div style={{ width: "20px" }}></div>
                <Chip icon={<Instagram onClick={() => share(3)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#DD2A7B", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
                <div style={{ width: "20px" }}></div>
                <Chip icon={<Facebook onClick={() => share(1)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#3B5998", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
                <div style={{ width: "20px" }}></div>
                <Chip icon={<Telegram onClick={() => share(4)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#6CC1E3", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "10px" }} />
                <div style={{ width: "20px" }}></div>
                <Chip icon={<LinkOutlined onClick={() => share(5)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "12px" }} />
              </div>
              <div style={{ height: "40px" }}></div>
            </>
          </SwipeableDrawer>

          <div style={{ height: "150px" }}></div>

        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default CardapioVenda;