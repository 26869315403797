import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, Typography, IconButton, Button, Card, CardHeader, Grid, Stepper, Step, StepLabel, Divider } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, CardMembershipOutlined, HomeOutlined, LocalPrintshopOutlined, PaymentOutlined, PaymentsOutlined, PixOutlined, PrintDisabledOutlined, PrintOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { toReal, createNewTheme } from '../../functions/utils';

import LinkIcon from '@mui/icons-material/Link';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

import { ColorlibConnector, ColorlibStepIcon } from '../../components/utils';

const steps = ["Cardápio", "Sacola", "Entrega", "Pagamento", "Revisar"];

function Pagamentos({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [valorLevar, setValorLevar] = useState("0,00");
  const [textButton, setTextButton] = useState("Continuar sem troco");
  const [troco, setTroco] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleModalTroco = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    selectedCard ? setButtonEnabled(false) : setButtonEnabled(true)
    setOpen(false);
  };

  const handleBack = () => {
    onChange("", "enderecos")
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    onChange("", "/", { replace: true, state: { page: "pedidos" } })
  };

  const handlePagamentos = (isTroco) => {
    if (isTroco) {
      Cookies.set('fmP', selectedCard, { expires: 7 });
      Cookies.set('sT', troco, { expires: 7 });
      onChange("", "finalizar")
      return
    }
    if (selectedCard === "dinheiro") {
      handleModalTroco()
      parseFloat(valorLevar.replaceAll(".", "").replaceAll(",", '.')) < 0 ? setButtonEnabled(true) : setButtonEnabled(false)
    } else {
      Cookies.set('fmP', selectedCard, { expires: 7 });
      Cookies.remove('sT');
      onChange("", "finalizar")
    }
  };

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
    setButtonEnabled(false)
  };

  const getValorTotal = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho) {
        Cookies.remove('crU');
        onChange("", "/", { replace: true, state: { page: page } })
      }
      setValorTotalCarrinho(toReal(valorTotal))
    }
  }

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    getInfos();
    getValorTotal();
    const pagamento = Cookies.get('fmP');
    setSelectedCard(pagamento);
    if (pagamento) {
      setButtonEnabled(false)
    }
  }, []);

  const handleTroco = (event) => {
    setTroco(event.target.value);
    const trocoLevar = parseFloat(event.target.value.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(valorTotalCarrinho.replaceAll(".", "").replaceAll(",", "."))
    isNaN(trocoLevar) ? setValorLevar("0,00") : setValorLevar(toReal(trocoLevar))
    trocoLevar < 0 ? setButtonEnabled(true) : setButtonEnabled(false)

    event.target.value ? setTextButton("Continuar") : setTextButton("Continuar sem troco")
  };

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/formas-pagamento.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        setFormasPagamento(response.data[0]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const returnFormas = (formas) => {
    if (!formas.formas_pagamento) {
      return
    }
    const pagamentosArray = formas.formas_pagamento.split("--");
    const creditodebito = pagamentosArray[1].split(",")
    const vrva = pagamentosArray[2].split(",")
    if (pagamentosArray[3]) {
      Cookies.set('cvP', pagamentosArray[3], { expires: 7 });
    }

    return <>
      {/* {pagamentosArray[3] && (
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'pix' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick('pix')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PixOutlined style={{ color: "#464545" }} />
              }
              title="PIX"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </Grid>
      )} */}
      {pagamentosArray[4] === "dinheiro" && (
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'dinheiro' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick('dinheiro')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PaymentsOutlined style={{ color: "#464545" }} />
              }
              title="DINHEIRO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </Grid>
      )}

      {pagamentosArray[5] === "link" && (
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'link de pagamento' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick('link de pagamento')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <LinkIcon style={{ color: "#464545" }} />
              }
              title="LINK DE PAGAMENTO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </Grid>
      )}

      {creditodebito[0] && (
        <>
          {creditodebito.map((cartoes) => (
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === cartoes ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick(cartoes)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <PaymentOutlined style={{ color: "#464545" }} />
                  }
                  title={cartoes.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </Grid>
          ))}
        </>
      )}

      {vrva[0] && (
        <>
          {vrva.map((vales) => (
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === vales ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick(vales)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <CardMembershipOutlined style={{ color: "#464545" }} />
                  }
                  title={vales.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </Grid>
          ))}
        </>
      )}
    </>
  }

  const returnFormasRapidas = (formas) => {
    if (!formas.formas_pagamento) {
      return
    }
    const pagamentosArray = formas.formas_pagamento.split("--");
    const creditodebito = pagamentosArray[1].split(",")
    const vrva = pagamentosArray[2].split(",")

    return <>
      {pagamentosArray[3] && (
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'pix' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick('pix')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PixOutlined style={{ color: "#464545" }} />
              }
              title="PIX"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </Grid>
      )}

      {creditodebito[0] && (
        <>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === "crédito" ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick("crédito")}>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                avatar={
                  <PaymentOutlined style={{ color: "#464545" }} />
                }
                title={"CRÉDITO"}
                titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
              />
            </Card>
            <div style={{ height: "8px" }}></div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === "débito" ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick("débito")}>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                avatar={
                  <PaymentOutlined style={{ color: "#464545" }} />
                }
                title={"DÉBITO"}
                titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
              />
            </Card>
            <div style={{ height: "8px" }}></div>
          </Grid>
        </>
      )}

      {vrva[0] && (
        <>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === "vr/va" ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB', cursor: "pointer" }} onClick={() => handleCardClick("vr/va")}>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                avatar={
                  <CardMembershipOutlined style={{ color: "#464545" }} />
                }
                title={"VR/VA"}
                titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
              />
            </Card>
            <div style={{ height: "8px" }}></div>
          </Grid>
        </>
      )}
    </>
  }

  const handleChangeStepper = (label) => {
    switch (label) {
      case "Sacola":
        onChange("", "carrinho")
        break;
      case "Cardápio":
        onChange("", "cardapio-venda")
        break;
      case "Entrega":
        onChange("", "enderecos")
        break;
    }
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>

            <div style={{ height: "16px" }}></div>

            <Stepper alternativeLabel activeStep={3} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label} onClick={() => handleChangeStepper(label)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <div style={{ height: "16px" }}></div>
            <Divider />
            <div style={{ height: "16px" }}></div>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
            />

            <Dialog
              open={open}
              onClose={handleCloseDialog}
              PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                  event.preventDefault();
                  handlePagamentos(true)
                  handleCloseDialog();
                },
              }}
            >
              <DialogTitle>Precisa de troco?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Digite o valor em dinheiro para levar o valor exato do troco:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Valor"
                  value={troco}
                  onChange={handleTroco}
                  fullWidth
                />
              </DialogContent>
              <DialogContentText style={{ fontSize: "12px", paddingLeft: "25px", marginTop: "-16px" }}>
                Valor total: R$ {valorTotalCarrinho}<br />
                Valor troco: R$ {valorLevar}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleCloseDialog} style={{ textTransform: "capitalize", fontWeight: "700" }}>Voltar</Button>
                <Button type="submit" disabled={buttonEnabled} style={{ textTransform: "capitalize", fontWeight: "700" }}>{textButton}</Button>
              </DialogActions>
            </Dialog>

            <div style={{ height: "12px" }}></div>

            <Typography style={{ color: "#333", fontSize: "17px", fontWeight: "bold" }}>
              Opções de pagamento rápidas
            </Typography>

            <div style={{ height: "12px" }}></div>

            <Grid container spacing={2}>
              {returnFormasRapidas(formasPagamento)}
            </Grid>

            <div style={{ height: "6px" }}></div>

            <Typography style={{ color: "#333", fontSize: "17px", fontWeight: "bold" }}>
              Selecione um meio de pagamento.
            </Typography>

            <div style={{ height: "12px" }}></div>

            <Grid container spacing={2}>
              {returnFormas(formasPagamento)}
            </Grid>
            <div style={{ borderTop: `solid 1px #DCDBDB`, position: "fixed", bottom: "0", right: "0", left: isMobile ? 0 : 315, backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
              <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={() => handlePagamentos(false)}>
                Continuar
              </Button>
            </div>
            <div style={{ height: "100px" }}></div>

          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Pagamentos;