import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, Typography, IconButton, Button, AppBar, Toolbar, Grid } from '@mui/material';
import { AccessTimeOutlined, CloseOutlined, HomeOutlined, LocalPrintshopOutlined, PrintDisabledOutlined, PrintOutlined, VolumeOffOutlined, VolumeUpOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { createNewTheme } from '../../functions/utils';

import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Finalizado({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [urlRedirect, setUrl] = useState("");
  const [isMesa, setIsMesa] = useState("");
  const [pedidos, setPedidos] = useState("");
  const [audio, setAudio] = React.useState(true);
  const [impressora, setImpressora] = React.useState(true);
  const [tempoEntrega, setTempoEntrega] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const getChavePix = () => {
    return Cookies.get('cvP');
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      onChange("", `/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: "home" } })
    } else {
      onChange("", "/", { replace: true, state: { page: "home" } })
    }
  };

  const handleAudio = (page) => {
    Cookies.set('Aud', !audio, { expires: 7 });
    setAudio(!audio)
  }

  const handleImpressora = (page) => {
    Cookies.set('IAt', !impressora, { expires: 7 });
    setImpressora(!impressora)
  }

  const handleInicio = () => {
    onChange("", "home")
  };

  const handleCopy = async () => {
    try {
      const chavePix = Cookies.get('cvP');
      await navigator.clipboard.writeText(chavePix);
      handleSnack("Chave PIX copiada com sucesso.")
    } catch (err) {
      handleSnack("Não foi possivel copiar a chave PIX")
    }
  };

  useEffect(() => {
    handleOpenLoading();

    const audio = Cookies.get("Aud") !== "false";
    const impressora = Cookies.get("IAt") !== "false";
    const timeDelivery = Cookies.get('tD');
    const arrTime = timeDelivery.split("(")
    setTempoEntrega(arrTime[1].replaceAll(")", ""))
    setAudio(audio)
    setImpressora(impressora)

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    // const telefoneCliente = Cookies.get('tUsr');
    // setUrl(`https://wa.me//55${telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")}?text=`);
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
    }
    const pedidoRealizado = Cookies.get('PdDrl');
    getPedidos(pedidoRealizado);
  }, []);

  const getPedidos = (idp) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido.php?idpedidos=${idp}`)
      .then(response => {
        if (response.data) {
          setPedidos(response.data);
          handleCloseLoading();
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
            />

            <div style={{ height: "70px" }}></div>
            {pedidos && (
              <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                <Typography style={{ textAlign: "center", fontSize: "14px", color: "#999", minWidth: "300px" }}>

                  <div style={{ height: "10px" }}></div>

                  <img src="https://dedicado.mely.online/imagens/smiling-face.png" style={{ width: "90px" }} />

                  <div style={{ height: "10px" }}></div>

                  <Typography style={{ textAlign: "center", fontSize: "24px", color: "#1B1B1B", minWidth: "300px" }}>
                    Pedido #{pedidos[0].data} criado!
                  </Typography>
                  <>
                    O pedido foi realizado com sucesso!
                  </>
                  {pedidos[0].formapagamento === "PIX" && (
                    <>
                      <br /><br />
                      Chave PIX: <b>{getChavePix()}</b>
                    </>
                  )}
                  <div style={{ height: "20px" }}></div>
                  {pedidos[0].formapagamento === "PIX" && (
                    <>
                      <Button variant="contained" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize", color: "#fff" }} onClick={handleCopy} target="_blank">Copiar chave PIX</Button>
                      <br /><br />
                    </>
                  )}
                  <div style={{ height: "20px" }}></div>
                  <Button fullWidth variant="contained" style={{ textTransform: "capitalize", fontWeight: "700", color: "#fff" }} onClick={handleInicio}>
                    Voltar ao inicio
                  </Button>
                </Typography>
              </Typography>
            )}

            <div style={{ height: "100px" }}></div>

          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Finalizado;