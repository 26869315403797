import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

import SideMenu from '../../components/SideMenu';
import Home from './Home';
import Pedidos from './Pedidos';
import Cardapio from './Cardapio';
import Inventario from './Inventario';
import QRCodeScreen from './QRCode';
import Personalizar from './Personalizar';
import Configuracoes from './Configuracoes';
import Suporte from './Suporte';
import Clientes from './Clientes';
import Despesas from './Despesas';
import '../../components/Scrollbar.css';
import Fechamento from './Fechamento';
import Dashboard from './Dashboard';
import PedidosDelivery from './PedidosDelivery';
import PedidosRetirada from './PedidosRetirada';
import PedidosMesa from './PedidosMesa';
import HistoricoPedidos from './HistoricoPedidos';
import CardapioVenda from './CardapioVenda';
import Enderecos from './Enderecos';
import Carrinho from './Carrinho';
import Pagamentos from './Pagamentos';
import Finalizar from './Finalizar';
import Finalizado from './Finalizado';
import SelecionarEndereco from './SelecionarEndereco';
import axios from 'axios';

function HomeRouter() {

  const state = useLocation().state;
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [value, setValue] = useState('');
  const [updateP, setUpdate] = useState('');

  useEffect(() => {

    setColorPrimary(Cookies.get("clPmr"))
    setColorSecondary(Cookies.get("clScr"))
    setColorTertiary(Cookies.get("clTcr"))

  }, []);

  const update = (event, newValue) => {
    setUpdate(newValue);
  };

  const handleChange = (event, newValue) => {
    // console.log(event)
    // console.log(newValue)
    if (event == "pg") {
      setUpdate(`STPG___${newValue}`);
    } else if (event == "updateNot") {
      console.log("Atualiza pedidos")
      getNovosPedidos()
      return
    }else {
      setUpdate(`UPDTCLP___${Cookies.get("clPmr").split(", ")}`);
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }
    setValue(newValue);
  };

  const getNovosPedidos = () => {

    const horariosestabelecimento = Cookies.get('hREst');
    const horariosArray = horariosestabelecimento.split(",");
    const d = new Date();
    let diasemana
    if (d.getDay() == 0) {
      diasemana = horariosArray[6]
    } else {
      diasemana = horariosArray[d.getDay() - 1]
    }

    if (diasemana == "Fechado") {
      var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), "00", "00") / 1000);
      var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), "23", "59") / 1000);
    } else {
      var unix = Math.round(+new Date() / 1000);
      const horarios = diasemana.split(" às ");
      const horario1 = horarios[0].split(":");
      var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
      const horario2 = horarios[1].split(":");
      var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);
    }

    if (unix2 <= unix1) {
      unix2 += 86400
    }

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get("Md");

    const post_url = urlBase + "/novos-pedidos.php";
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      unix1: unix1,
      unix2: unix2
    });
    axios.post(post_url, json)
      .then(async resp => {
        const response = resp.data

        if (!response) {
          Cookies.remove('nTMs')
          Cookies.remove('nTDn')
          Cookies.remove('nTRn')
          return;
        }
        response.mesasNovos > 0 ? Cookies.set('nTMs', true, { expires: 7 }) : Cookies.remove('nTMs')
        response.deliveryNovos > 0 ? Cookies.set('nTDn', true, { expires: 7 }) : Cookies.remove('nTDn')
        response.retiradaNovos > 0 ? Cookies.set('nTRn', true, { expires: 7 }) : Cookies.remove('nTRn')

        if (response.mesasNovos > 0 || response.deliveryNovos > 0 || response.retiradaNovos > 0) {
          Cookies.remove("sPdd")
          const nt = Cookies.get("nTUpdt") === "true";

          const mesasNovas = Cookies.get("msNv") ? Cookies.get("msNv") : 0;
          const deliveryNovos = Cookies.get("dlNv") ? Cookies.get("dlNv") : 0;
          const retiradaNovos = Cookies.get("rtNv") ? Cookies.get("rtNv") : 0;
          if (!nt || mesasNovas != response.mesasNovos || deliveryNovos != response.deliveryNovos || retiradaNovos != response.retiradaNovos) {
            Cookies.set('msNv', response.mesasNovos, { expires: 7 });
            Cookies.set('dlNv', response.deliveryNovos, { expires: 7 });
            Cookies.set('rtNv', response.retiradaNovos, { expires: 7 });
          }
          console.log(response.mesasNovos)
          console.log(response.deliveryNovos)
          console.log(response.retiradaNovos)
          Cookies.set('nTUpdt', true, { expires: 7 })

        } else {
          Cookies.set('sPdd', true, { expires: 7 });
          Cookies.remove("msNv")
          Cookies.remove("dlNv")
          Cookies.remove("rtNv")
          Cookies.remove('nTUpdt')
        }
      })
      .catch(error => {
        console.log(error)
        console.log("ERRO CÓDIGO 12")
      });
  }

  const renderContent = () => {
    switch (value) {
      case 'home':
        return <Home onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'cardapio':
        return <Cardapio onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'pedidos':
        return <Pedidos onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'inventario':
        return <Inventario onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'qrcode':
        return <QRCodeScreen onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'personalizar':
        return <Personalizar onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'configuracoes':
        return <Configuracoes onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'suporte':
        return <Suporte onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'clientes':
        return <Clientes onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'despesas':
        return <Despesas onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'fechamento':
        return <Fechamento onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      // PDV
      case 'dashboard':
        return <Dashboard onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'entrega':
        return <PedidosDelivery onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'retirada':
        return <PedidosRetirada onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'mesa':
        return <PedidosMesa onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'historico':
        return <HistoricoPedidos onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'cardapio-venda':
        return <CardapioVenda onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'carrinho':
        return <Carrinho onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'pagamentos':
        return <Pagamentos onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'enderecos':
        return <Enderecos onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'selecionar-endereco':
        return <SelecionarEndereco onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'finalizar':
        return <Finalizar onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'finalizado':
        return <Finalizado onChange={handleChange} update={update} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        setValue("home")
        return <Home />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <SideMenu onChange={handleChange} update={updateP} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />

      {renderContent()}

    </ThemeProvider>
  );
}

export default HomeRouter;