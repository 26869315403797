import { Paper } from '@mui/material';
import { ArticleOutlined, HomeOutlined, LocalMallOutlined, PersonOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from './functions/utils';

import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import Home from './pages/Home';
import Cardapio from './pages/Cardapio';
import Pedidos from './pages/Pedidos';
import Perfil from './pages/Perfil';
import Cookies from 'js-cookie';
import axios from 'axios';

const AppHome = () => {

  const [colorPrimary, setColorPrimary] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');
  const [colorTertiary, setColorTertiary] = useState('');
  const [value, setValue] = useState('');
  const [tipoLoja, setTipoLoja] = useState("restaurante");
  const [onlyMenu, setOnlyMenu] = useState(false);
  const [isMesa, setIsMesa] = useState(false);

  const state = useLocation().state;

  const handleConfigs = (isMesa, onlyMenu, tipoLoja) => {
    if (isMesa) {
      setValue("cardapio");
      setIsMesa(isMesa);
    }
    if (onlyMenu) {
      setValue("cardapio");
      setOnlyMenu(true)
    }
    if (tipoLoja) {
      setTipoLoja(tipoLoja)
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state !== null) {
      if (state.page !== null) {
        setValue(state.page)
        state.page = null
      }
    }

    const onlyMenu = Cookies.get('eNbM') === "true";
    if (onlyMenu) {
      setOnlyMenu(onlyMenu)
    }

    const colorPrimaryC = Cookies.get('clPmr');
    if (!colorPrimaryC) {
      getColors()
      return
    }
    const colorSecondaryC = Cookies.get('clScr');
    const colorTertiaryC = Cookies.get('clTcr');

    setColorPrimary(colorPrimaryC)
    setColorSecondary(colorSecondaryC)
    setColorTertiary(colorTertiaryC)

  }, []);


  const getColors = () => {
    let urlBase = window.location.href;

    if(urlBase.includes("?")) {
      let arrUrl = urlBase.split("?");
      urlBase = arrUrl[0];
    }

    if (urlBase.includes("localhost")) {
      urlBase = "https://dedicado.mely.online/";
    }
    axios.get(`${urlBase}/api/get-colors.php`, {})
      .then(response => {
        const arrColors = response.data.split(`\n`)
        const colorPrimaryC = arrColors[1].replaceAll("    --primary: ", "").replaceAll(";", "")
        const colorSecondaryC = arrColors[2].replaceAll("    --secondary: ", "").replaceAll(";", "")
        const colorTertiaryC = arrColors[3].replaceAll("    --tertiary: ", "").replaceAll(";", "")

        Cookies.set('clPmr', colorPrimaryC, { expires: 7 });
        Cookies.set('clScr', colorSecondaryC, { expires: 7 });
        Cookies.set('clTcr', colorTertiaryC, { expires: 7 });
        setColorPrimary(colorPrimaryC)
        setColorSecondary(colorSecondaryC)
        setColorTertiary(colorTertiaryC)
      })
      .catch(error => console.error('Error:', error));
  }

  const handleGetTipoLoja = () => {
    switch (tipoLoja) {
      case "restaurante":
        return <>Cardápio</>
      case "loja":
        return <>Catálogo</>
    }
  };

  const handleTipoLojaIcon = () => {
    switch (tipoLoja) {
      case "restaurante":
        return <RestaurantMenuIcon style={{ marginBottom: "6px" }} />
      case "loja":
        return <LocalMallOutlined style={{ marginBottom: "6px" }} />
    }
  };

  const renderContent = () => {
    switch (value) {
      case 'home':
        return <Home onChange={handleChange} changeConfigs={handleConfigs} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'cardapio':
        return <Cardapio onChange={handleChange} changeConfigs={handleConfigs} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'pedidos':
        return <Pedidos onChange={handleChange} changeConfigs={handleConfigs} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      case 'perfil':
        return <Perfil onChange={handleChange} changeConfigs={handleConfigs} colorPrimary={colorPrimary} colorSecondary={colorSecondary} colorTertiary={colorTertiary} />;
      default:
        setValue("home")
        return <Home />;
    }
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div>
        {/* Renderiza o conteúdo da tela selecionada */}
        {renderContent()}

        {/* Bottom Navigation */}
        {!onlyMenu && (
          <Paper style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation value={value} onChange={handleChange} showLabels={true} style={{ backgroundColor: `rgba(${colorPrimary})`, height: "75px" }} >
              {!isMesa && (
                <BottomNavigationAction label="Home" value="home" icon={<HomeOutlined style={{ marginBottom: "6px" }} />} style={{
                  fontSize: "12px", fontWeight: "bold",
                  color: value === "home" ? "#FFF" : "#FFFFFFCC",
                  '& .MuiBottomNavigationAction-label': {
                  }, borderBottom: value === "home" ? "solid #FFF 2px" : "none",
                  '& .MuiBottomNavigationAction-label': {
                  }
                }} />
              )}
              <BottomNavigationAction label={handleGetTipoLoja()} value="cardapio" icon={handleTipoLojaIcon()} style={{
                fontSize: "12px", fontWeight: "bold",
                color: value === "cardapio" ? "#FFF" : "#FFFFFFCC",
                '& .MuiBottomNavigationAction-label': {
                }, borderBottom: value === "cardapio" ? "solid #FFF 2px" : "none",
                '& .MuiBottomNavigationAction-label': {
                }
              }} />
              {!onlyMenu && (
                <BottomNavigationAction label="Pedidos" value="pedidos" icon={<ArticleOutlined style={{ marginBottom: "6px" }} />} style={{
                  fontSize: "12px", fontWeight: "bold",
                  color: value === "pedidos" ? "#FFF" : "#FFFFFFCC",
                  '& .MuiBottomNavigationAction-label': {
                  }, borderBottom: value === "pedidos" ? "solid #FFF 2px" : "none",
                  '& .MuiBottomNavigationAction-label': {
                  }
                }} />
              )}
              {!onlyMenu && !isMesa && (
                <BottomNavigationAction label="Perfil" value="perfil" icon={<PersonOutlined style={{ marginBottom: "6px" }} />} style={{
                  fontSize: "12px", fontWeight: "bold",
                  color: value === "perfil" ? "#FFF" : "#FFFFFFCC",
                  '& .MuiBottomNavigationAction-label': {
                  }, borderBottom: value === "perfil" ? "solid #FFF 2px" : "none",
                  '& .MuiBottomNavigationAction-label': {
                  }
                }} />
              )}
            </BottomNavigation>
          </Paper>
        )}
      </div>
    </ThemeProvider>
  );
};

export default AppHome;
