import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../../components/LoadingComponent';

import { Typography, TextField, InputAdornment, Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, Error, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

import backgroundImage from "../../imagens/bg-1.png";
import logoImage from "../../imagens/logo-mely-white.png";
import Snackbar from '@mui/material/Snackbar';

function Login() {

  const state = useLocation().state;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState("login");
  const [email, setEmail] = useState("");
  const [emailValidado, setEmailValidado] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [senha, setSenha] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessageSenha, setErrorMessageSenha] = useState("");
  const [errorSenha, setErrorSenha] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [urlBase, setUrlBase] = useState("");
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "204, 43, 2");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setErrorEmail(false)
    setErrorMessageEmail("")
    setEmailValidado(false)
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
    setErrorSenha(false)
    setErrorMessageSenha("")
  };

  useEffect(() => {
    handleOpenLoading();

    setColorPrimary(Cookies.get("clPmr"))
    setColorSecondary(Cookies.get("clScr"))
    setColorTertiary(Cookies.get("clTcr"))
    if (!Cookies.get("clPmr")) {
      setColorPrimary("204, 43, 2")
    }

    if (!Cookies.get('UBs')) {
      navigate("/estabelecimento");
    }
    setUrlBase(Cookies.get('UBs'))
    const isLogged = Cookies.get("isLogged");
    const isStore = Cookies.get("isStore");

    if (isLogged && isStore) {
      navigate("/estabelecimento/home", { replace: true, state: { page: "home" } });
      handleCloseLoading();
      return
    }
    handleCloseLoading();
  }, []);

  const handleLogin = () => {
    if (screen === "login") {
      const urlbase = Cookies.get('UBs');
      handleOpenLoading();
      if (emailValidado) {
        const post_url = urlbase + "/login-estabelecimento.php";
        const json = JSON.stringify({
          email: email,
          senha: senha,
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            console.log(response)
            if (response === null || response === undefined) {
              handleErrorSenha("Senha inválida")
              handleCloseLoading();
            }
            else {
              if (response.resposta === "email invalido") {
                handleErrorSenha("E-mail inválido")
                handleCloseLoading();
              } else {
                for (let user of response) {
                  Cookies.set('dsEst', user.descricao, { expires: 7 });
                  Cookies.set('emEst', user.email, { expires: 7 });
                  Cookies.set('endEst', user.endereco, { expires: 7 });
                  Cookies.set('fMsP', user.formas_pagamento, { expires: 7 });
                  Cookies.set('hREst', user.horarios, { expires: 7 });
                  Cookies.set('iEst', user.idestabelecimentos, { expires: 7 });
                  Cookies.set('nmEst', user.nome, { expires: 7 });
                  Cookies.set('txEst', user.taxa_entrega, { expires: 7 });
                  Cookies.set('tEst', user.telefones, { expires: 7 });
                  Cookies.set('tsEst', user.tipo_segmento, { expires: 7 });
                  Cookies.set('UBs', user.url_base.includes("/api") ? user.url_base : `${user.url_base}/api`, { expires: 7 });
                  Cookies.set('FTs', user.isfisrtaccess, { expires: 7 });
                  Cookies.set('isLogged', true, { expires: 7 });
                  Cookies.set('isStore', true, { expires: 7 });
                  navigate("/estabelecimento/home", { replace: true, state: { page: "home" } });
                }
                handleCloseLoading();
              }
            }

          })
          .catch(error => {
            handleErrorSenha("Não foi possivel entrar em sua conta")
            handleCloseLoading();
          });
      } else {
        const post_url = "https://mely.online/api/valida-email-estabelecimento.php";
        const json = JSON.stringify({
          email: email,
          senha: senha,
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response === null || response === undefined) {
              handleErrorEmail("E-mail não encontrado no sistema")
              handleCloseLoading();
            }
            else {
              if (response.resposta === "email invalido") {
                handleErrorEmail("E-mail inválido")
                handleCloseLoading();
              } else if (response.resposta === "nao possui") {
                handleErrorEmail("E-mail não encontrado no sistema")
                handleCloseLoading();
              } else {
                Cookies.set('UBs', `${response[0].URL_CT}/api`);
                setEmailValidado(true);
                handleCloseLoading();
              }
            }

          })
          .catch(error => {
            handleErrorEmail("Não foi possivel conectar ao servidor")
            handleCloseLoading();
          });
      }
    } else {
      handleReenviarSenha();
      return
    }
  }

  const handleErrorEmail = (message) => {
    setErrorEmail(true);
    setErrorMessageEmail(message)
  }

  const handleErrorSenha = (message) => {
    setErrorSenha(true);
    setErrorMessageSenha(message)
  }

  const handleShowSenha = () => {
    setShowSenha(!showSenha)
  }

  const handleBackLogin = () => {
    resetCampos()
    setScreen("login")
  }

  const handleEsqueciSenha = () => {
    setEmailValidado(false)
    resetCampos()
    setScreen("esqueci")
  }

  const resetCampos = () => {
    setErrorSenha(false);
    setErrorMessageSenha("")
    setErrorEmail(false);
    setErrorMessageEmail("")
  }

  const handleReenviarSenha = () => {
    const urlbase = Cookies.get('UBs');
    setEmailValidado(false)
    handleOpenLoading();
    const post_url = urlbase + "/reenviar-senha-estabelecimento.php";
    const json = JSON.stringify({
      email: email,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        console.log(response)
        if (response === null || response === undefined) {
          handleErrorEmail("Não foi possivel reenviar sua senha")
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleErrorEmail("E-mail inválido")
            handleCloseLoading();
          } else if (response.resposta === "email nao cadastrado") {
            handleErrorEmail("E-mail não cadastrado")
            handleCloseLoading();
            return;
          } else if (response.resposta === "falha") {
            handleErrorEmail("Não foi possivel reenviar sua senha")
            handleCloseLoading();
            return;
          } else {
            handleSnack("Um e-mail de redefinição foi enviado para você.")
            handleCloseLoading();
          }
        }
      })
      .catch(error => {
        handleErrorEmail("Não foi possivel reenviar sua senha")
        handleCloseLoading();
      });
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
        }}>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <Box
          sx={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
            maxWidth: 500,
            minWidth: 400,
          }}
        >

          <Typography style={{ textAlign: "left", fontSize: "36px", fontWeight: "600" }}>
            {screen === "login" ? <>Login</> : <>Esqueceu a senha?<br /><Typography style={{ fontWeight: "normal", fontSize: 16, lineHeight: 1.7 }}>Não se preocupe! Digite seu e-mail abaixo e enviaremos um link para redefinir sua senha.</Typography></>}
          </Typography>

          <div style={{ height: 20 }}></div>



          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
            E-mail
          </Typography>
          <div style={{ height: 4 }}></div>
          <TextField
            fullWidth
            hiddenLabel
            value={email}
            onChange={handleEmail}
            variant="outlined"
            size="large"
            placeholder="Digite aqui seu e-mail"
            type="text"
            error={errorEmail}
            helperText={errorMessageEmail}
            InputProps={{
              endAdornment: (
                <>
                  {errorEmail &&
                    <InputAdornment style={{ color: "#d32f2f" }}>
                      <Error />
                    </InputAdornment>
                  }
                </>
              ),
            }}
          />

          <div style={{ height: 20, }}></div>

          {emailValidado &&
            <>
              <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                Senha
              </Typography>
              <div style={{ height: 4 }}></div>
              <TextField
                fullWidth
                hiddenLabel
                value={senha}
                onChange={handleSenha}
                variant="outlined"
                size="large"
                placeholder="Digite aqui sua senha"
                type={showSenha ? "text" : "password"}
                error={errorSenha}
                helperText={errorMessageSenha}
                InputProps={{
                  endAdornment: (
                    <>
                      {errorSenha ?
                        <InputAdornment style={{ color: "#d32f2f" }}>
                          <Error />
                        </InputAdornment>
                        :
                        <InputAdornment style={{ cursor: "pointer" }}>
                          {showSenha ?
                            <VisibilityOutlined onClick={handleShowSenha} />
                            :
                            <VisibilityOffOutlined onClick={handleShowSenha} />
                          }
                        </InputAdornment>
                      }
                    </>
                  ),
                }}
              />
            </>
          }
          {screen === "login" &&
            <Button variant="text" size="large" aria-label="settings" style={{ float: "right", textTransform: "none", fontWeight: "bold", width: "180px" }} onClick={handleEsqueciSenha}>
              Esqueci minha senha
            </Button>
          }
          {emailValidado && screen === "login" && <br />}
          {screen === "login" && <br />}
          <div style={{ height: 40 }}></div>

          <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold", color: "white" }} onClick={handleLogin}>
            {screen === "login" ? <>Entrar</> : <>Enviar e-mail</>}
          </Button>

          <div style={{ height: 10 }}></div>

          {screen === "esqueci" &&
            <Button size="large" startIcon={<ArrowBack />} fullWidth aria-label="settings" style={{ textTransform: "none", fontWeight: "bold" }} onClick={handleBackLogin}>
              Voltar para o login
            </Button>
          }

          <Typography style={{ display: "flex", justifyContent: "center", fontSize: 14, color: "white", position: "fixed", top: 0, left: 0 }}><img src={logoImage} style={{ height: 100, marginLeft: "16px", marginTop: 3 }} /></Typography>

          <div style={{ height: 10 }}></div>

          <div style={{ position: "fixed", bottom: 0, left: 10, fontSize: 16, color: "white" }}>
            © v2.1.1.0
          </div>

        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Login;